var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-hover", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ hover }) {
          return [
            _c(
              "v-container",
              {
                staticClass: "tile",
                class: [
                  _vm.isAssembly ? "assemblyTile" : "articleTile",
                  { "elevation-10": hover },
                ],
              },
              [
                _c(
                  "v-overlay",
                  {
                    attrs: { absolute: true },
                    model: {
                      value: _vm.deleteOverlay,
                      callback: function ($$v) {
                        _vm.deleteOverlay = $$v
                      },
                      expression: "deleteOverlay",
                    },
                  },
                  [
                    _c("v-progress-circular", {
                      attrs: { indeterminate: "", color: "accent" },
                    }),
                  ],
                  1
                ),
                _c("article-tile-header", {
                  attrs: {
                    article: _vm.article,
                    hover: hover,
                    isAssembly: _vm.isAssembly,
                    isAssemblyView: _vm.isAssemblyView,
                    isLocked: _vm.isLocked,
                    isArticleLockedAndNotAdmin: _vm.isArticleLockedAndNotAdmin,
                    isArticleUnlockedAndAdmin: _vm.isArticleUnlockedAndAdmin,
                    isArticleLockedAndAdmin: _vm.isArticleLockedAndAdmin,
                    isCalculating: _vm.isCalculating,
                  },
                  on: {
                    openArticleEditDialog: _vm.openArticleEditDialog,
                    checkDeleteArticle: _vm.checkDeleteArticle,
                  },
                }),
                _c(
                  "v-row",
                  {
                    staticClass: "ma-0",
                    staticStyle: { "min-height": "200px" },
                  },
                  [
                    _c(
                      "v-col",
                      { staticClass: "pa-2", attrs: { cols: "6" } },
                      [
                        _c("v-img", {
                          staticClass: "previewImage",
                          style: {
                            cursor:
                              _vm.isCalculating || _vm.isMigrated
                                ? "not-allowed"
                                : "pointer",
                          },
                          attrs: {
                            contain: "",
                            width: "160px",
                            height: "160px",
                            title:
                              "Öffnet das CAD Modell im Browser. Im integrierten CAD Editor von Blexon können Die Gewinde, Senkungen, Gravuren, Schweißnähte usw. einfügen / bearbeiten. Ausserdem Maße kontrollieren, Meldungen anzeigen und Fehlerursachen beheben.",
                            src: _vm.getThumbnailUrl(_vm.article.articleId),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openCadDialog()
                            },
                          },
                        }),
                        _vm.hasFeatures
                          ? _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "span",
                                            _vm._g({}, on),
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "accent" } },
                                                [_vm._v("mdi-cogs")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "ml-2",
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                  },
                                                },
                                                [_vm._v("Bearbeitungen")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c("div", [
                                  _vm.articleHasPowderCoating
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "table-cell",
                                          },
                                        },
                                        [
                                          _c("span", {
                                            style: {
                                              display: "inline-block",
                                              width: "20px",
                                              height: "20px",
                                              background:
                                                _vm.article
                                                  .powderCoatingTreatment.powder
                                                  .surfaceColor.hexCode + "ff",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "10px",
                                                "vertical-align": "top",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "RAL " +
                                                  _vm._s(
                                                    _vm.article
                                                      .powderCoatingTreatment
                                                      .powder.surfaceColor
                                                      .ralCode
                                                  ) +
                                                  " (" +
                                                  _vm._s(
                                                    _vm.article
                                                      .powderCoatingTreatment
                                                      .powder.surfaceColor.name
                                                  ) +
                                                  ") - " +
                                                  _vm._s(
                                                    _vm.getStructureText(
                                                      _vm.article
                                                        .powderCoatingTreatment
                                                        .powder.structure
                                                    )
                                                  ) +
                                                  " - " +
                                                  _vm._s(
                                                    _vm.getFinishText(
                                                      _vm.article
                                                        .powderCoatingTreatment
                                                        .powder.finish
                                                    )
                                                  )
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.isAssembly &&
                                  _vm.article.featureDtos != null &&
                                  _vm.article.featureDtos.length > 0
                                    ? _c(
                                        "div",
                                        _vm._l(
                                          _vm.article.featureDtos,
                                          function (feature, index) {
                                            return _c("div", { key: index }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(feature.count) +
                                                  " x " +
                                                  _vm._s(feature.friendlyName) +
                                                  " "
                                              ),
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    : _vm._e(),
                                  !_vm.isAssembly && _vm.article.hasEngravings
                                    ? _c("div", [_vm._v(" Gravur ")])
                                    : _vm._e(),
                                  _vm.hasWeldlines
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.getWeldlineString()) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "text-caption pa-0",
                        attrs: { cols: "6" },
                      },
                      [
                        _vm.article.customerArticleId !== ""
                          ? _c("p", { staticClass: "ma-0 pa-0" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "pointerElement",
                                  on: { click: _vm.openArticleEditDialog },
                                },
                                [
                                  _c("strong", [_vm._v("Artikel Nr:")]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.article.customerArticleId) +
                                      " "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _c("p", { staticClass: "ma-0 pa-0" }, [
                          _c("strong", [_vm._v("Blexon Nr:")]),
                          _vm._v(" " + _vm._s(_vm.article.articleId) + " "),
                        ]),
                        !_vm.isAssembly
                          ? _c("div", [
                              _vm.article.materialId > 0
                                ? _c("p", { staticClass: "ma-0 pa-0" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.article.materialname) +
                                        " "
                                    ),
                                  ])
                                : _c("div", [
                                    _vm.article.werkstoffId > 0
                                      ? _c("p", { staticClass: "ma-0 pa-0" }, [
                                          _c("strong", [_vm._v("Werkstoff:")]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.article.werkstoffname
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.article.thickness > 0
                                      ? _c("p", { staticClass: "ma-0 pa-0" }, [
                                          _c("strong", [_vm._v("Dicke:")]),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.article.thickness) +
                                              "mm "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                            ])
                          : _vm._e(),
                        !_vm.isAssembly
                          ? _c("div", [
                              _c("span", {
                                staticStyle: {
                                  display: "inline-block",
                                  "margin-right": "4px",
                                  height: "10px",
                                  width: "10px",
                                  "background-color": "rgb(69, 160, 205)",
                                },
                              }),
                              _vm._v(
                                " Oberseite" +
                                  _vm._s(_vm.getZusatzbezeichnungOberseite())
                              ),
                              _c("br"),
                              _c("span", {
                                staticStyle: {
                                  display: "inline-block",
                                  "margin-right": "4px",
                                  height: "10px",
                                  width: "10px",
                                  "background-color": "rgb(140, 160, 170)",
                                },
                              }),
                              _vm._v(
                                " Unterseite" +
                                  _vm._s(_vm.getZusatzbezeichnungUnterseite())
                              ),
                              _c("br"),
                            ])
                          : _vm._e(),
                        _vm.article.length > 0
                          ? _c("div", [
                              _c("p", { staticClass: "ma-0 pa-0" }, [
                                _c("strong", [_vm._v("LxBxH:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("round2Decimal")(
                                        _vm.article.length
                                      )
                                    ) +
                                    " x " +
                                    _vm._s(
                                      _vm._f("round2Decimal")(_vm.article.width)
                                    ) +
                                    " x " +
                                    _vm._s(
                                      _vm._f("round2Decimal")(
                                        _vm.article.height
                                      )
                                    ) +
                                    "mm "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.isAssembly
                          ? _c("p", { staticClass: "ma-0 pa-0" }, [
                              _c("strong", [_vm._v("Gewicht: ")]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("round3Decimal")(_vm.assemblyWewicht)
                                  ) +
                                  "kg "
                              ),
                            ])
                          : _c(
                              "p",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.article.length > 0,
                                    expression: "article.length > 0",
                                  },
                                ],
                                staticClass: "ma-0 pa-0",
                              },
                              [
                                _c("strong", [_vm._v("Gewicht:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("round3Decimal")(
                                        _vm.article.weight
                                      )
                                    ) +
                                    "kg "
                                ),
                              ]
                            ),
                        !_vm.isAssembly
                          ? _c(
                              "v-row",
                              { staticClass: "ma-0" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pa-0 pointerElement",
                                    attrs: { cols: "auto" },
                                    on: { click: _vm.openArticleEditDialog },
                                  },
                                  [
                                    _c("strong", [_vm._v("Entgraten: ")]),
                                    _c(
                                      "a",
                                      { staticClass: "articleTileLink" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getEntgratartText(
                                              _vm.article.entgratart
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isPowderCoatingAvailable
                          ? _c(
                              "v-row",
                              {
                                staticClass: "ma-0 pointerElement",
                                attrs: { align: "end" },
                                on: {
                                  click: function ($event) {
                                    _vm.isPowdercoatingPossibleDialog = true
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pa-0",
                                    attrs: { cols: "12" },
                                  },
                                  [
                                    _c("strong", [
                                      _vm._v("Pulverbeschichtung: "),
                                    ]),
                                    _c(
                                      "a",
                                      { staticClass: "articleTileLink" },
                                      [_vm._v(_vm._s(_vm.powderCoatingText))]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.isAssembly && _vm.certificates.length > 0
                          ? _c(
                              "v-row",
                              { staticClass: "ma-0" },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "pa-0",
                                    attrs: { cols: "11" },
                                  },
                                  [
                                    _c(
                                      "strong",
                                      {
                                        staticStyle: { "margin-right": "10px" },
                                      },
                                      [_vm._v("Zertifikat:")]
                                    ),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.certificate,
                                          expression: "certificate",
                                        },
                                      ],
                                      staticStyle: {
                                        cursor: "pointer",
                                        "accent-color": "#efbb20",
                                        "vertical-align": "text-bottom",
                                        "margin-right": "10px",
                                        position: "relative",
                                        top: "-1px",
                                      },
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(_vm.certificate)
                                          ? _vm._i(_vm.certificate, null) > -1
                                          : _vm.certificate,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.certificate,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.certificate = $$a.concat([
                                                  $$v,
                                                ]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.certificate = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.certificate = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.certificates[0])),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  { staticClass: "pa-0", attrs: { cols: "1" } },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "blexon-button",
                                        staticStyle: {
                                          width: "16px",
                                          height: "16px",
                                          "margin-bottom": "1px",
                                        },
                                        attrs: {
                                          title:
                                            "Weitere Informationen über Zertifikat",
                                          icon: "",
                                          "x-small": "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            _vm.certificateHelperDialog = true
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { "x-small": "" } },
                                          [_vm._v("mdi-help")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isAssembly
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-top": "5px",
                                  display: "flex",
                                  "flex-wrap": "wrap",
                                  gap: "5px 5px",
                                },
                              },
                              [
                                _vm._l(
                                  _vm.article.subArticleIds.slice(0, 6),
                                  function (subarticleId) {
                                    return _c("v-img", {
                                      key: subarticleId,
                                      attrs: {
                                        height: "60px",
                                        width: "60px",
                                        contain: "",
                                        src: _vm.getThumbnailUrl(subarticleId),
                                      },
                                    })
                                  }
                                ),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.article.subArticleIds.length > 6,
                                        expression:
                                          "article.subArticleIds.length > 6",
                                      },
                                    ],
                                  },
                                  [_vm._v("mehr...")]
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm.isAssembly && _vm.article.subArticleIds.length > 0
                          ? _c(
                              "v-row",
                              { staticClass: "mt-3" },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "", "open-delay": "500" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "blexon-article-button",
                                                        attrs: {
                                                          small: "",
                                                          block: "",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.navigateTo(
                                                              `catalogue/assembly/${_vm.article.articleId}`
                                                            )
                                                          },
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        " Baugruppe anzeigen "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            " Zeigt alle Artikel dieser Baugruppe an. "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.isCalculating
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { staticClass: "text-center", attrs: { cols: "12" } },
                          [
                            _c("v-progress-circular", {
                              attrs: {
                                size: "26",
                                indeterminate: "",
                                color: "accent",
                              },
                            }),
                            !_vm.isAssembly
                              ? _c("p", { staticClass: "text-caption" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.article.calculatingMessage) +
                                      " "
                                  ),
                                ])
                              : _c("p", { staticClass: "text-caption" }, [
                                  _vm._v(
                                    " Einige Artikel in dieser Baugruppe werden zurzeit kalkuliert. "
                                  ),
                                ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c(
                      "v-row",
                      { staticClass: "mx-0 mt-2 align-center" },
                      [
                        !_vm.isAssembly
                          ? _c(
                              "v-col",
                              { staticClass: "pa-0", attrs: { cols: "6" } },
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      top: "",
                                      disabled:
                                        _vm.article.camMessages.filter(
                                          (m) => m.state == 0
                                        ).length <= 0,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _vm.isStatusChipLinkToCad
                                                ? _c(
                                                    "v-chip",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "text-caption",
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                        },
                                                        attrs: {
                                                          color:
                                                            _vm.articleStateColor,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.openCadDialog()
                                                          },
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-avatar",
                                                        { attrs: { left: "" } },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.articleStateIcon
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.articleStateText
                                                          ) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "v-chip",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "text-caption",
                                                        attrs: {
                                                          color:
                                                            _vm.articleStateColor,
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-avatar",
                                                        { attrs: { left: "" } },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.articleStateIcon
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.articleStateText
                                                          ) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _vm._l(
                                      _vm.article.camMessages.filter(
                                        (m) => m.state == 0
                                      ),
                                      function (message, index) {
                                        return _c(
                                          "p",
                                          { key: index, staticClass: "ma-0" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                "- " + _vm._s(message.title)
                                              ),
                                            ]),
                                          ]
                                        )
                                      }
                                    ),
                                    _vm.article.state == 2
                                      ? _c("p", [
                                          _vm._v(
                                            " Öffnen Sie den Artikel im CAD und überprüfen Sie den Fehler. Sie können den Artikel zur kostenlosen Abklärung an unser Helpdesk senden. "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.article.state == 6
                                      ? _c("p", [
                                          _vm._v(
                                            " Bitte öffnen Sie den Artikel im CAD und definieren Sie, was mit den erkannten Bearbeitungen gemacht werden soll. "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          : _c(
                              "v-col",
                              { staticClass: "pa-0", attrs: { cols: "6" } },
                              [
                                !_vm.isCalculating && _vm.showState
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { top: "", "open-delay": "500" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-chip",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "text-caption",
                                                        attrs: {
                                                          color:
                                                            _vm.articleStateColor,
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-avatar",
                                                        { attrs: { left: "" } },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.articleStateIcon
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.articleStateText
                                                          ) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _vm.article.subArticleIds.length <= 0 &&
                                        _vm.article.camMessages.length <= 0
                                          ? _c("span", [
                                              _vm._v(
                                                " Baugruppe enthält keine gültigen Volumenkörper. Evtl. sind die Körper offen oder nur als Oberfläche definiert. "
                                              ),
                                            ])
                                          : _vm.article.subArticleIds.length <=
                                              0 &&
                                            _vm.article.camMessages.length > 0
                                          ? _c(
                                              "span",
                                              _vm._l(
                                                _vm.article.camMessages,
                                                function (message, index) {
                                                  return _c(
                                                    "span",
                                                    { key: index },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            message.shortDescription
                                                          )
                                                      ),
                                                      _c("br"),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _c("span", [
                                              _vm._v(
                                                "Klicken Sie auf 'Baugruppe anzeigen' um die Status der einzelnen Artikel zu sehen."
                                              ),
                                            ]),
                                      ]
                                    )
                                  : _c("v-progress-circular", {
                                      attrs: {
                                        small: "",
                                        indeterminate: "",
                                        color: "accent",
                                      },
                                    }),
                              ],
                              1
                            ),
                        _c(
                          "v-col",
                          { staticClass: "pa-0", attrs: { cols: "6" } },
                          [
                            _c("article-tile-pricelist", {
                              attrs: {
                                article: _vm.article,
                                isAssembly: _vm.isAssembly,
                                isAssemblyView: _vm.isAssemblyView,
                              },
                              on: { addToCart: _vm.addQuantityToCart },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                _vm.isCartButtonRowVisible
                  ? _c(
                      "v-row",
                      { staticClass: "mx-0 my-0 mt-2 align-center" },
                      [
                        _c(
                          "v-col",
                          { staticClass: "pa-0 pr-2", attrs: { cols: "6" } },
                          [
                            _c(
                              "v-form",
                              {
                                model: {
                                  value: _vm.isValidCartAmount,
                                  callback: function ($$v) {
                                    _vm.isValidCartAmount = $$v
                                  },
                                  expression: "isValidCartAmount",
                                },
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    placeholder: "Menge",
                                    type: "number",
                                    rules: _vm.allowedCartAmountRule,
                                    color: "accent",
                                    dense: "",
                                    "hide-details": "",
                                  },
                                  model: {
                                    value: _vm.amountToAddToCart,
                                    callback: function ($$v) {
                                      _vm.amountToAddToCart = _vm._n($$v)
                                    },
                                    expression: "amountToAddToCart",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { staticClass: "pa-0", attrs: { cols: "6" } },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "", "open-delay": "500" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "span",
                                            _vm._g({}, on),
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "pa-0 blexon-article-button",
                                                  attrs: {
                                                    small: "",
                                                    block: "",
                                                    disabled:
                                                      !_vm.isCartButtonEnabled,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.addToCart()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        left: "",
                                                        small: "",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-cart-arrow-down"
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" In Warenkorb "),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm.isAssemblyView
                                  ? _c("span", [
                                      _vm._v(
                                        " Artikel, die Teil einer Baugruppe sind, können nicht einzeln bestellt werden. "
                                      ),
                                    ])
                                  : !_vm.isCartButtonEnabled &&
                                    _vm.hasEngravings &&
                                    _vm.articleHasPowderCoating
                                  ? _c("span", [
                                      _vm._v(
                                        " Artikel mit Pulverbeschichtung und Gravur können nicht bestellt werden."
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " Die Gravur würde von der Pulverbeschichtung vollständig überdeckt."
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " Entfernen Sie entweder die Gravur oder die Pulverbeschichtung, um den Artikel bestellen zu können. "
                                      ),
                                    ])
                                  : !_vm.canBeProduced
                                  ? _c("span", [
                                      _vm._v(
                                        " Artikel kann aktuell in " +
                                          _vm._s(_vm.region) +
                                          " nicht bestellt werden. "
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        " Fügt den Artikel zum Warenkorb hinzu. "
                                      ),
                                    ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-row",
                  { staticClass: "mx-0 my-0" },
                  [
                    _vm.isCadButtonVisible
                      ? _c(
                          "v-col",
                          { staticClass: "pa-0 mt-2", attrs: { cols: "12" } },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "", "open-delay": "500" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "blexon-article-button",
                                                attrs: { small: "", block: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openCadDialog()
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                " CAD Modell anzeigen / bearbeiten "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c("span", [
                                  _vm._v(" Öffnet das CAD Modell im Browser."),
                                  _c("br"),
                                  _vm._v(
                                    " Im integrierten CAD Editor von Blexon können Sie Gewinde, Senkungen, Gravuren, Schweißnähte usw. einfügen / bearbeiten."
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " Ausserdem Maße kontrollieren, Meldungen anzeigen und Fehlerursachen beheben. "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isHelpdeskButtonVisible
                      ? _c(
                          "v-col",
                          { staticClass: "pa-0 mt-2", attrs: { cols: "12" } },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "", "open-delay": "500" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "blexon-article-button my-0",
                                                attrs: {
                                                  small: "",
                                                  block: "",
                                                  disabled:
                                                    _vm.isHelpdeskButtonDisabled,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.reasonToSendArticleToHelpdesk = 0
                                                    _vm.showHelpdeskDialogue = true
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [_vm._v(" An Helpdesk senden ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    " Artikel wird an unser Helpdesk gesendet und kostenlos analysiert."
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " Sie werden informiert, sobald wir Ihren Artikel bearbeitet haben. "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.isAssembly && _vm.article.migrated
                      ? _c(
                          "v-col",
                          { staticClass: "pa-0 mt-2", attrs: { cols: "12" } },
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "", "open-delay": "500" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              {
                                                staticClass:
                                                  "blexon-article-button",
                                                attrs: {
                                                  disabled:
                                                    _vm.article.calculating,
                                                  small: "",
                                                  block: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.revalidate()
                                                  },
                                                },
                                              },
                                              on
                                            ),
                                            [_vm._v(" Artikel blexonisieren ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    " Der Artikel wurde noch nicht mit der neuesten Version blexonisiert."
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    " Um den Artikel zu bestellen, im CAD zu überprüfen oder zu ändern, klicken Sie bitte hier. "
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "v-dialog",
                  {
                    attrs: { "content-class": "specialSize", persistent: "" },
                    model: {
                      value: _vm.cadDialog,
                      callback: function ($$v) {
                        _vm.cadDialog = $$v
                      },
                      expression: "cadDialog",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "background-color": "#f3f3f4",
                          padding: "0px",
                        },
                      },
                      [
                        _vm.cadDialog
                          ? _c("iframe", {
                              staticStyle: {
                                padding: "0px",
                                margin: "0px",
                                border: "0px",
                                width: "100%",
                              },
                              attrs: {
                                loading: "lazy",
                                height: _vm.currentWindowHeight,
                                src: _vm.articleCadViewUrl,
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
                _c("article-edit-dialog", {
                  attrs: {
                    article: _vm.article,
                    articleEditDialog: _vm.articleEditDialog,
                  },
                  on: {
                    closeDialog: function ($event) {
                      _vm.articleEditDialog = false
                    },
                  },
                }),
                _c("article-delete-warning-dialog", {
                  attrs: {
                    articleDeleteWarningDialog: _vm.articleDeleteWarningDialog,
                    articleCartNames: _vm.articleCartNames,
                    articleId: _vm.articleId,
                    assemblyInfo: _vm.assemblyInfo,
                    isAssemblyView: _vm.isAssemblyView,
                  },
                  on: {
                    setDeleteOverlay: _vm.setDeleteOverlay,
                    closeDialog: function ($event) {
                      _vm.articleDeleteWarningDialog = false
                    },
                  },
                }),
                _c("send-to-helpdesk-dialog", {
                  attrs: {
                    showHelpdeskDialogue: _vm.showHelpdeskDialogue,
                    articleId: _vm.articleId,
                    reason: _vm.reasonToSendArticleToHelpdesk,
                  },
                  on: {
                    closeDialog: function ($event) {
                      _vm.showHelpdeskDialogue = false
                    },
                  },
                }),
                _c("too-many-calculating-dialog", {
                  attrs: {
                    tooManyCalculatingDialog: _vm.tooManyCalculatingDialog,
                  },
                  on: {
                    closeDialog: function ($event) {
                      _vm.tooManyCalculatingDialog = false
                    },
                  },
                }),
                _c("article-treatment-edit-dialog", {
                  attrs: {
                    articleTreatmentEditDialog: _vm.articleTreatmentEditDialog,
                    articles: [_vm.article],
                  },
                  on: {
                    closeDialog: function ($event) {
                      _vm.articleTreatmentEditDialog = false
                    },
                  },
                }),
                _c("is-powdercoating-possible-dialog", {
                  attrs: {
                    isPowdercoatingPossibleDialog:
                      _vm.isPowdercoatingPossibleDialog,
                    articleIds: [_vm.articleId],
                  },
                  on: {
                    closeDialog: function ($event) {
                      _vm.isPowdercoatingPossibleDialog = false
                    },
                    openPowdercoatingDialog: function ($event) {
                      _vm.isPowdercoatingPossibleDialog = false
                      _vm.articleTreatmentEditDialog = true
                    },
                    helpdeskSendenClicked: function ($event) {
                      _vm.isPowdercoatingPossibleDialog = false
                      _vm.reasonToSendArticleToHelpdesk = 1
                      _vm.showHelpdeskDialogue = true
                    },
                  },
                }),
                _vm.certificateHelperDialog
                  ? _c("certificate-helper-dialog", {
                      attrs: {
                        certificateHelperDialog: _vm.certificateHelperDialog,
                      },
                      on: {
                        closeDialog: function ($event) {
                          _vm.certificateHelperDialog = false
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }