var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-dialog",
    {
      attrs: { width: "500" },
      on: {
        "click:outside": function ($event) {
          return _vm.closeDialog()
        },
        keydown: _vm.checkEsc,
      },
      model: {
        value: _vm.isPowdercoatingPossibleDialog,
        callback: function ($$v) {
          _vm.isPowdercoatingPossibleDialog = $$v
        },
        expression: "isPowdercoatingPossibleDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _vm.calculating
            ? _c("v-card-title", [
                _vm._v("Pulverbeschichtung wird untersucht..."),
              ])
            : _vm.failReasons.length > 0
            ? _c("v-card-title", [_vm._v("Pulverbeschichtung nicht wählbar")])
            : _c("v-card-title", [_vm._v("Pulverbeschichtung möglich")]),
          _vm.calculating
            ? _c(
                "v-card-text",
                { staticClass: "text-center" },
                [
                  _c("v-progress-circular", {
                    attrs: { size: "26", indeterminate: "", color: "accent" },
                  }),
                ],
                1
              )
            : _c("v-card-text", [
                !_vm.isMultipleArticles
                  ? _c("p", [
                      _vm._v(
                        "Eine Pulverbeschichtung ist für diesen Artikel aus folgendem Grund nicht möglich:"
                      ),
                    ])
                  : _c("p", [
                      _vm._v(
                        "Eine Pulverbeschichtung ist für die aufgelisteten Artikel nicht möglich:"
                      ),
                    ]),
                !_vm.isMultipleArticles && _vm.failReasons.length > 0
                  ? _c("div", [
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.failReasons[0].failReason),
                        },
                      }),
                    ])
                  : _vm.failReasons.length > 0
                  ? _c(
                      "div",
                      _vm._l(_vm.failReasons, function (reason) {
                        return _c("div", { key: reason.articleId }, [
                          _c("b", [_vm._v(_vm._s(reason.articleId) + ": ")]),
                          _c("span", {
                            domProps: { innerHTML: _vm._s(reason.failReason) },
                          }),
                          _c("br"),
                          _c("br"),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              !_vm.isMultipleArticles
                ? _c(
                    "v-btn",
                    {
                      staticClass: "blexon-button ml-3",
                      attrs: { disabled: _vm.calculating, text: "", small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.sendToHelpdesk()
                        },
                      },
                    },
                    [_vm._v(" An Helpdesk senden ")]
                  )
                : _vm._e(),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { disabled: _vm.calculating, text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_vm._v(" Schliessen ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }