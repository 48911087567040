var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticStyle: { display: "flex" } }, [
        _c("div", [
          _c("img", {
            staticStyle: { "max-height": "120px", "max-width": "120px" },
            attrs: { src: _vm.getThumbnailUrl(_vm.complaint.part.articleId) },
          }),
        ]),
        _c("div", { staticClass: "ml-5" }, [
          _c("h2", [_vm._v(_vm._s(_vm.complaint.part.description))]),
          _c("p", [_vm._v("Id: " + _vm._s(_vm.complaint.part.articleId))]),
          _c("p", [
            _vm._v(
              "Material: " + _vm._s(_vm.complaint.part.materialBezeichnung)
            ),
          ]),
        ]),
      ]),
      _c("p", [
        _vm._v(
          " Damit wir Ihre Beanstandung schnell und unkompliziert bearbeiten können, brauchen wir zu jeder Position einige Angaben. "
        ),
      ]),
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "h3",
            [
              _c(
                "v-icon",
                { staticClass: "pr-3", attrs: { color: "accent", medium: "" } },
                [_vm._v(" mdi-help ")]
              ),
              _vm._v(" Welcher Art ist ihre Beanstandung? "),
            ],
            1
          ),
          _c("v-select", {
            attrs: {
              rules: _vm.requiredChoiceRule,
              disabled: _vm.isReadonly,
              color: "accent",
              "return-object": false,
              items: _vm.beanstandungsTypen,
              label: "Bitte auswählen",
            },
            on: {
              change: function ($event) {
                _vm.validateForm()
                _vm.$emit("changed")
              },
            },
            model: {
              value: _vm.complaint.complainType,
              callback: function ($$v) {
                _vm.$set(_vm.complaint, "complainType", $$v)
              },
              expression: "complaint.complainType",
            },
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.partsAreMissing,
                  expression: "!partsAreMissing",
                },
              ],
            },
            [
              _c(
                "h3",
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "pr-3",
                      attrs: { color: "accent", medium: "" },
                    },
                    [_vm._v("mdi-upload")]
                  ),
                  _vm._v(
                    " Laden Sie Fotos hoch, welche die Beanstandung möglichst klar zeigen "
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                [
                  _vm._l(_vm.complaint.files, function (image, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "mr-3",
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                          width: "120px",
                          border: "1px solid lightgray",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          [
                            _c("v-img", {
                              staticStyle: { cursor: "pointer" },
                              attrs: {
                                src: _vm.getComplaintImageUri(image.guid),
                                height: "120px",
                                width: "120px",
                                contain: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onImageClick(index)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "blexon-button",
                            staticStyle: { "align-self": "center" },
                            attrs: {
                              icon: "",
                              disabled: _vm.isReadonly,
                              title: "Bild löschen",
                            },
                            on: {
                              click: function ($event) {
                                _vm.deleteImage(index)
                                _vm.$emit("changed")
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-delete")])],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  !_vm.uploadingImages
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "ma-3 blexon-button",
                          staticStyle: { "align-self": "center" },
                          attrs: { fab: "", disabled: _vm.isReadonly },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.$refs.upload.click()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      )
                    : _c("v-progress-circular", {
                        staticStyle: { "align-self": "center" },
                        attrs: { indeterminate: "", color: "accent" },
                      }),
                ],
                2
              ),
              _vm.complaint.files.length < 1
                ? _c("p", { staticClass: "caption" }, [
                    _vm._v("Mindestens ein Bild muss hochgeladen werden."),
                  ])
                : _vm._e(),
              _c("input", {
                ref: "upload",
                staticStyle: { display: "none" },
                attrs: {
                  type: "file",
                  multiple: "",
                  accept: "image/png, image/jpeg",
                },
                on: {
                  change: function ($event) {
                    _vm.uploadFiles($event.target.files)
                    _vm.$emit("changed")
                  },
                },
              }),
              _c(
                "v-dialog",
                {
                  attrs: { width: "100%" },
                  model: {
                    value: _vm.showGallery,
                    callback: function ($$v) {
                      _vm.showGallery = $$v
                    },
                    expression: "showGallery",
                  },
                },
                [
                  _vm.showGallery
                    ? _c(
                        "v-carousel",
                        {
                          attrs: { interval: "1000000", dark: "" },
                          model: {
                            value: _vm.currentImage,
                            callback: function ($$v) {
                              _vm.currentImage = $$v
                            },
                            expression: "currentImage",
                          },
                        },
                        _vm._l(_vm.complaint.files, function (image, i) {
                          return _c(
                            "v-carousel-item",
                            { key: i },
                            [
                              _c("v-img", {
                                attrs: {
                                  src: _vm.getComplaintImageUri(image.guid),
                                  height: "100%",
                                  width: "100%",
                                  contain: "",
                                },
                              }),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.complaint.part.menge !== 1
            ? _c("div", [
                _c(
                  "h3",
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "pr-3",
                        attrs: { color: "accent", medium: "" },
                      },
                      [_vm._v("mdi-help")]
                    ),
                    _vm._v(" Wie viele Teile dieser Position sind betroffen? "),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "flex-start",
                      "align-items": "baseline",
                    },
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { small: "", disabled: _vm.isReadonly },
                        on: {
                          click: function ($event) {
                            _vm.setMaxParts()
                            _vm.$emit("changed")
                          },
                        },
                      },
                      [_vm._v(" Alle ")]
                    ),
                    _c("v-text-field", {
                      staticClass: "shrink ml-3",
                      attrs: {
                        disabled: _vm.isReadonly,
                        rules: _vm.requiredNumberRule,
                        type: "number",
                        color: "accent",
                        label: "Anzahl Teile",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.$emit("changed")
                        },
                      },
                      model: {
                        value: _vm.complaint.numberOfWrongParts,
                        callback: function ($$v) {
                          _vm.$set(_vm.complaint, "numberOfWrongParts", $$v)
                        },
                        expression: "complaint.numberOfWrongParts",
                      },
                    }),
                    _c("span", [
                      _vm._v("/ " + _vm._s(_vm.complaint.part.menge)),
                    ]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "h3",
            [
              _c(
                "v-icon",
                { staticClass: "pr-3", attrs: { color: "accent", medium: "" } },
                [_vm._v("mdi-help")]
              ),
              _vm._v(" Was beanstanden Sie? "),
            ],
            1
          ),
          _c("v-textarea", {
            attrs: {
              rows: "3",
              disabled: _vm.isReadonly,
              label: "Beanstandung",
              color: "accent",
              rules: _vm.requiredRule,
            },
            on: {
              change: function ($event) {
                return _vm.$emit("changed")
              },
              blur: function ($event) {
                _vm.complaint.complainDescription =
                  _vm.complaint.complainDescription.trim()
              },
            },
            model: {
              value: _vm.complaint.complainDescription,
              callback: function ($$v) {
                _vm.$set(_vm.complaint, "complainDescription", $$v)
              },
              expression: "complaint.complainDescription",
            },
          }),
          _c(
            "h3",
            [
              _c(
                "v-icon",
                { staticClass: "pr-3", attrs: { color: "accent", medium: "" } },
                [_vm._v("mdi-help")]
              ),
              _vm._v(" Was erwarten Sie von uns? "),
            ],
            1
          ),
          _c(
            "v-radio-group",
            {
              attrs: { row: "" },
              on: {
                change: function ($event) {
                  _vm.validateForm(), _vm.$emit("changed")
                },
              },
              model: {
                value: _vm.complaint.expectationType,
                callback: function ($$v) {
                  _vm.$set(_vm.complaint, "expectationType", $$v)
                },
                expression: "complaint.expectationType",
              },
            },
            _vm._l(_vm.expectationTypes, function (item) {
              return _c("v-radio", {
                key: item.value,
                attrs: {
                  color: "accent",
                  disabled: _vm.isReadonly,
                  label: item.text,
                  value: item.value,
                },
              })
            }),
            1
          ),
          _c("v-textarea", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.complaint.expectationType !== _vm.ersatzExpectation,
                expression: "complaint.expectationType !== ersatzExpectation",
              },
            ],
            attrs: {
              rows: "3",
              disabled: _vm.isReadonly,
              label: "Erwartung",
              color: "accent",
              rules: _vm.requiredExpectationDescription,
            },
            on: {
              change: function ($event) {
                return _vm.$emit("changed")
              },
              blur: function ($event) {
                _vm.complaint.expectation = _vm.complaint.expectation.trim()
              },
            },
            model: {
              value: _vm.complaint.expectation,
              callback: function ($$v) {
                _vm.$set(_vm.complaint, "expectation", $$v)
              },
              expression: "complaint.expectation",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }