import { render, staticRenderFns } from "./ArticleTilePricelist.vue?vue&type=template&id=381c5a50"
import script from "./ArticleTilePricelist.vue?vue&type=script&lang=ts"
export * from "./ArticleTilePricelist.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\src\\Blexon.Kundenportal.Client\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('381c5a50')) {
      api.createRecord('381c5a50', component.options)
    } else {
      api.reload('381c5a50', component.options)
    }
    module.hot.accept("./ArticleTilePricelist.vue?vue&type=template&id=381c5a50", function () {
      api.rerender('381c5a50', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Catalogue/ArticleTileComponents/ArticleTilePricelist.vue"
export default component.exports