import { DeliveryType } from './deliveryType';
import { ManufacturingOfferDto } from './manufacturingOfferDto';
import { OfferFailReason } from './offerFailReason';
import { OrderDiscountDto } from './orderDiscountDto';

export class OfferDto {
    public calculationId: number = 0;
    public orderDiscounts: OrderDiscountDto[] = [];
    public manufacturingOffers: ManufacturingOfferDto[] = new Array<ManufacturingOfferDto>();
    public selectedManufacturerId: number = 0;
    public deliveryType: DeliveryType = DeliveryType.Post;
    public offerFailReason: OfferFailReason = OfferFailReason.None;
    public deliveryDate: Date = new Date();
    public customKommissionsnummer: string = '';
    public deliveryChoice: string = 'delivery';
    public defaultManufacturerId?: number = 0;
    public totalOfAllArticlesInCart: number = 0;

}