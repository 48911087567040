var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.showPriceList
    ? _c(
        "div",
        [
          _c(
            "v-menu",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "pa-0 article-button",
                                attrs: {
                                  small: "",
                                  color: _vm.priceStateColor,
                                  block: "",
                                  disabled: _vm.isCalculating,
                                  title: "Öffnet die Preisliste des Artikels.",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.loadPrices()
                                  },
                                },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _vm.isCalculating
                              ? _c("v-progress-circular", {
                                  staticClass: "mr-2",
                                  attrs: { size: "15", indeterminate: "" },
                                })
                              : _vm.priceStateInvalid
                              ? _c(
                                  "v-icon",
                                  { staticClass: "mr-2", attrs: { small: "" } },
                                  [_vm._v("mdi-close")]
                                )
                              : _vm._e(),
                            _vm._v(" Preisliste "),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                1916169034
              ),
            },
            [
              _c(
                "div",
                {
                  staticStyle: { "background-color": "white", padding: "5px" },
                },
                [
                  _vm.isLoadingPrices
                    ? _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              indeterminate: "",
                              size: "20",
                              color: "accent",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.priceStateInvalid
                    ? _c("div", [
                        _c("span", { staticClass: "text-caption" }, [
                          _vm._v(
                            " Für diesen Artikel konnten die Preise nicht berechnet werden."
                          ),
                          _c("br"),
                          _vm._v(
                            " Bitte senden Sie den Artikel für eine kostenlose Abklärungen an unser Helpdesk. "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c(
                    "speech-bubble-float",
                    {
                      attrs: {
                        hintId: _vm.priceListHintId,
                        message: _vm.priceListHintText,
                        title: _vm.priceListHintTitle,
                      },
                    },
                    [
                      _c(
                        "v-simple-table",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.pricesValid,
                              expression: "pricesValid",
                            },
                          ],
                          attrs: { dense: "" },
                        },
                        [
                          _c("thead", [
                            _c("tr", { staticStyle: { cursor: "pointer" } }, [
                              _c(
                                "td",
                                {
                                  staticStyle: { "vertical-align": "center" },
                                  attrs: {
                                    colspan:
                                      _vm.availablePriceModels.length + 2,
                                    title:
                                      "Erfahren Sie hier, wie Sie Ihre Artikel noch günstiger bekommen.",
                                  },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "blexon-button mr-2",
                                      attrs: { "x-small": "", icon: "" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          _vm.preisInformationenDialog = true
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { "x-small": "" } },
                                        [_vm._v("mdi-help")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" Preishinweis "),
                                ],
                                1
                              ),
                            ]),
                            _vm.availablePriceModels.length > 1
                              ? _c("tr", [
                                  _c("th"),
                                  _c(
                                    "th",
                                    {
                                      staticClass: "text-center",
                                      attrs: {
                                        colspan:
                                          _vm.availablePriceModels.length,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " Preis / Stk. [" +
                                          _vm._s(_vm.waehrung) +
                                          "] "
                                      ),
                                    ]
                                  ),
                                  _c("th"),
                                ])
                              : _vm._e(),
                            _vm.availablePriceModels.length > 1
                              ? _c(
                                  "tr",
                                  [
                                    _c("th", [_vm._v(" Menge ")]),
                                    _vm._l(
                                      _vm.availablePriceModels,
                                      function (model) {
                                        return _c(
                                          "th",
                                          {
                                            key: model.value,
                                            staticClass: "text-center",
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(model.name) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    _c("th"),
                                  ],
                                  2
                                )
                              : _c("tr", [
                                  _c("th", [_vm._v(" Menge ")]),
                                  _c("th", { staticClass: "text-right" }, [
                                    _vm._v(
                                      " Preis/Stk. [" +
                                        _vm._s(_vm.waehrung) +
                                        "] "
                                    ),
                                  ]),
                                  _c("th"),
                                ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.sortedPrices, function (price, index) {
                              return _c(
                                "tr",
                                { key: index, staticClass: "text-caption" },
                                [
                                  price.quantity == 1000
                                    ? _c("td", [
                                        _vm._v("ab " + _vm._s(price.quantity)),
                                      ])
                                    : _c("td", [
                                        _vm._v(_vm._s(price.quantity)),
                                      ]),
                                  _vm._l(
                                    _vm.availablePriceModels,
                                    function (model) {
                                      return _c(
                                        "td",
                                        {
                                          key: model.value,
                                          staticClass: "text-right",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getModelPrice(
                                                  price.quantity,
                                                  model.value
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        "padding-right": "5px",
                                        "padding-left": "3px",
                                      },
                                    },
                                    [
                                      !_vm.isAssemblyView
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                color: "accent",
                                                small: "",
                                                title:
                                                  price.quantity +
                                                  " Stück in den Warenkorb",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addToCart(
                                                    price.quantity
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" mdi-cart-arrow-down ")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c("article-price-info-dialog", {
            attrs: { preisInformationenDialog: _vm.preisInformationenDialog },
            on: {
              closeDialog: function ($event) {
                _vm.preisInformationenDialog = false
              },
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }