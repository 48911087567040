
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import httpClient from '../../store/httpClient';

@Component
export default class IsPowdercoatingPossibleDialog extends Vue {
	@Prop()
	private isPowdercoatingPossibleDialog!: boolean;
	@Prop()
	private articleIds!: number[];

	private calculating: boolean = false;
	private failReasons: { articleId: number; failReason: string}[] = [];

	private get isMultipleArticles() {
		return this.articleIds.length > 1;
	}

	@Watch("isPowdercoatingPossibleDialog")
	private async onToggle() {
		if (this.isPowdercoatingPossibleDialog) {
			// Check if article can be powdercoated
			try {
				this.calculating = true;
				this.failReasons = [];
				for (let articleId of this.articleIds) {
					const response = await httpClient().get(`article/IsPowderCoatingAllowed?articleId=${articleId}`);
					if (response.data !== "") {
						this.failReasons.push({ articleId: articleId, failReason: response.data });
					}
				}
				if (this.failReasons.length <= 0) {
					this.openPowdercoatingDialog();
					return;
				}
			} catch(ex) {
				console.error(`Error during determination if article can be powdercoated. ${ex}`);
				this.$store.dispatch('setSnackbarErrorText', 'Es konnte nicht bestimmt werden, ob der Artikel pulverbeschichtet werden kann.');				
			} finally {
				this.calculating = false;
			}
		}
	}

	private openPowdercoatingDialog() {
		this.$emit("openPowdercoatingDialog");
	}

	private closeDialog() {
		this.$emit('closeDialog');
	}

	private sendToHelpdesk() {
		this.$emit('helpdeskSendenClicked')
	}

	private checkEsc(keyPress: KeyboardEvent) {
		if (keyPress.key === 'Escape') {
			this.closeDialog();
		}
	}
}

