
import { Component, Vue, Prop } from "vue-property-decorator";
import httpClient from "@/store/httpClient";
import { CustomerArticleDto } from "@/dtos/customerArticleDto";
import { ArticleState } from "@/dtos/articleState";
import { PriceState } from "@/dtos/priceState";
import { HintId } from "@/dtos/hintId";
import { PriceListDto } from "@/dtos/priceListDto";
import PriceModelDefault, { PriceModel } from "@/dtos/priceModel"
import { PriceDto } from "@/dtos/priceDto";
import SpeechBubbleFloat from '@/views/Dialogs/SpeechBubbleFloat.vue';
import ArticlePriceInfoDialog from '@/views/Dialogs/ArticlePriceInfoDialog.vue'

@Component({
	components: {
        SpeechBubbleFloat,
		ArticlePriceInfoDialog,
	},
})
export default class ArticleTilePricelist extends Vue {
	@Prop()
	private article!: CustomerArticleDto;
	@Prop()
	private isAssembly!: boolean;
	@Prop()
	private isAssemblyView!: boolean;
	private isLoadingPrices: boolean = false;
	private isLoaded: boolean = false;
	private priceModels = PriceModelDefault.allPriceModels;
	private preisInformationenDialog: boolean = false;
	private priceListHintTitle = 'Den finalen Preis inkl. Mengenrabatt sehen Sie im Warenkorb.';
    private priceListHintText = 'Mehrere Artikel mit gleichen Bearbeitungen oder gleichem Material ' + 
		'führen zu einem Preisnachlass. Um den definitiven Preis zu kalkulieren, ' + 
		'stellen Sie bitte einen Warenkorb zusammen und lassen Sie sich innert Sekunden ein Angebot ' + 
		'inkl. Liefertermin berechnen.';

	private async loadPrices() {
		if (this.article.priceLists && this.article.priceLists.length >= 1) {
			console.log("Prices already loaded.");
			return;
		}
		try {
			this.isLoadingPrices = true;
            const response = await httpClient().get(`Article/LoadPrices?articleId=${this.article.articleId}`);
            if(response.data.length <= 0) {
                this.article.priceState = PriceState.NotCalculated;
            } else {
				this.$store.commit("addOrUpdateArticlePricelists", response.data);
            }
			this.isLoaded = true;
		} catch(ex) {
			console.log(`Could not load prices for article ${this.article.articleId}`)
            this.$store.dispatch('setSnackbarErrorText', 'Preise für Artikel konnten nicht geladen werden.');
		} finally {
			this.isLoadingPrices = false;
		}
	}

	private get subArticles() : CustomerArticleDto[] {
		if (!this.isAssembly) return [];
        const subArticles = this.$store.state.articles
			.filter((a:CustomerArticleDto) => a.parentArticleId == this.article.articleId);
        return subArticles;
    }

    private get showPriceList(): boolean {
		let showPrice = true;
		if(this.article.calculating 
				|| (this.article.state !== ArticleState.OK && this.article.state !== ArticleState.Warning) 
				|| this.article.migrated 
				|| this.article.locked) {
			showPrice = false;
		}
        if(this.subArticles.some((sa: CustomerArticleDto) => sa.calculating)
				|| this.subArticles.some((sa: CustomerArticleDto) => sa.state != ArticleState.OK && sa.state != ArticleState.Warning)
				|| this.subArticles.some((sa: CustomerArticleDto) => sa.migrated)
				|| this.subArticles.some((sa: CustomerArticleDto) => sa.locked)) {
			showPrice = false;
		}
		return showPrice;
    }

	private get priceStateInvalid() {
		if (this.isLoadingPrices) return false;
		let allPriceStates = [];
		if (this.isAssembly) {
			this.subArticles.forEach((sa:CustomerArticleDto) => allPriceStates.push(sa.priceState));
		} else {
			allPriceStates.push(this.article.priceState);
		}
		return allPriceStates.some((s: PriceState) => 
            s == PriceState.NotCalculated ||
			s == PriceState.Invalid ||
			s == PriceState.Failed);
	}

	private get waehrung() {
        return this.$store.getters.waehrung;
    }

	private get pricesValid() {
		if (this.isLoadingPrices) return false;
		let allPriceStates = [];
		if (this.isAssembly) {
			this.subArticles.forEach((sa:CustomerArticleDto) => allPriceStates.push(sa.priceState));
		} else {
			allPriceStates.push(this.article.priceState);
		}
		const allOk = allPriceStates.every((s: PriceState) => s == PriceState.Ok);
		return allOk;
	}

	private get priceStateColor() {
        return this.priceStateInvalid ? '#FFCDD2' : 'white';
    }

	private get isCalculating() {
		if (this.isAssembly) {
			return this.article.calculating 
				|| this.article.priceState == PriceState.Calculating 
				|| this.subArticles.some((sa: CustomerArticleDto) => sa.calculating)
				|| this.anySubArticlesHavePriceState(PriceState.Calculating);
		} else {
			return this.article.calculating || this.article.priceState == PriceState.Calculating
		}
	}

	private anySubArticlesHavePriceState(priceState: PriceState) : boolean {
        return this.subArticles.some((sa: CustomerArticleDto) => sa.priceState == priceState)
    }

	private get priceListHintId(): number {
        return HintId.ShopPriceList;
    }

	private get availablePriceModels() {
        if (!this.article.priceLists) return [];
		let availablePriceModels = this.priceModels.filter((p:any) => 
			this.article.priceLists.some((pl:PriceListDto) => pl.priceModel == p.value));
		return availablePriceModels;
	}

	private get sortedPrices() {
        if (!this.article.priceLists || this.article.priceLists.length <= 0) {
            return [];
        } else {
            let sortedPrices: PriceDto[] = Array.from(this.article.priceLists[0].prices);
            return sortedPrices.sort((a:any, b:any) => a.quantity - b.quantity);
        }
    }

	private getModelPrice(quantity: number, model: PriceModel): string {
        if (!this.article.priceLists) return "";
        let list = this.article.priceLists.filter((pl:PriceListDto) => pl.priceModel == model)[0];
        return list.prices.filter((p:PriceDto) => p.quantity === quantity)[0].price.toFixed(2);
    }

	private addToCart(quantity: number) {
		this.$emit('addToCart', quantity)
    }




}
