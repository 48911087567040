
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ComplaintDto } from '../../dtos/ticket/complaintDto';

@Component
export default class ChoosePosition extends Vue {
    @Prop()
    private positions!: ComplaintDto[];
    @Prop()
    private ticketClosed!: boolean;

    private formatPrice(price: number) {
        return price.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
    }

    private getThumbnailUrl(articleId: string) {
        return this.$store.getters.getThumbnailUrl(articleId, undefined);
    }
}
