var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-hover", {
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ hover }) {
          return [
            _c(
              "v-card",
              {
                staticStyle: { "min-width": "200px", "max-width": "500px" },
                attrs: { elevation: hover ? 8 : 2 },
              },
              [
                _c("v-card-title", [_vm._v(" Großmengenrabatt möglich ")]),
                _c(
                  "v-card-text",
                  [
                    _vm._v(
                      " Ihr Warenkorb enthält Stückzahlen und / oder Materialverbrauch, welche unter Umständen einen Großmengenrabatt ermöglichen."
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      " Bei größeren Mengen kann oft der Materialeinkauf optimiert oder die Herstellung der Teile automatisiert werden. Sie können die Bestellung entweder sofort, gemäß unserem automatischen Angebot, auslösen oder Sie geben uns "
                    ),
                    _c("strong", [_vm._v("1 - 2 Arbeitstage")]),
                    _vm._v(
                      " Zeit, um Ihren Auftrag von unseren Fertigungspartnern analysieren zu lassen, damit Sie Ihnen einen auftragsspezifischen Großmengenrabatt unterbreiten können."
                    ),
                    _c("br"),
                    _vm._v(
                      " Bitte wählen Sie den spätesten Liefertermin (ab Werk). Allgemein gilt: Je länger Sie uns Zeit für die Produktion geben, umso höher wird der Auftragsrabatt. "
                    ),
                    _c("v-select", {
                      attrs: {
                        items: _vm.allowedDates,
                        label: "Spätester Liefertermin",
                        "menu-props": "auto",
                        "hide-details": "",
                        color: "accent",
                        "no-data-text": "Keine Liefertermine verfügbar",
                        "prepend-icon": "mdi-calendar-range",
                        "single-line": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "selection",
                            fn: function (data) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm._f("formatDate")(data.item)) +
                                    " "
                                ),
                              ]
                            },
                          },
                          {
                            key: "item",
                            fn: function (data) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm._f("formatDate")(data.item)) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                      model: {
                        value: _vm.deliveryDate,
                        callback: function ($$v) {
                          _vm.deliveryDate = $$v
                        },
                        expression: "deliveryDate",
                      },
                    }),
                  ],
                  1
                ),
                _c("v-card-actions", [
                  _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "blexon-button",
                          attrs: {
                            disabled:
                              _vm.isLoading ||
                              !_vm.isOfferRequestAllowedDueToDeliveryType(),
                            title: "Angebot anfordern",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.requestOffer(false)
                            },
                          },
                        },
                        [
                          _vm.isLoading
                            ? _c("v-progress-circular", {
                                staticClass: "mr-2",
                                attrs: { size: "15", indeterminate: "" },
                              })
                            : _vm._e(),
                          _vm._v(" Angebot anfordern "),
                        ],
                        1
                      ),
                      !_vm.isOfferRequestAllowedDueToDeliveryType()
                        ? _c("div", { staticStyle: { "font-size": "0.7em" } }, [
                            _vm._v(
                              " Diese Auswahl ist nur bei Lieferung oder bei Rampenabholung in " +
                                _vm._s(_vm.getTownOfManualOfferManufacturer()) +
                                " möglich. "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }