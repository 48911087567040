var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.article.isAssembly
    ? _c(
        "v-card",
        { staticClass: "mr-2 mb-3", attrs: { width: "100%" } },
        [
          _c(
            "v-card-text",
            { on: { click: _vm.toggleExpand } },
            [
              _c("cart-position-item", {
                attrs: { position: _vm.position, isPartOfAssembly: false },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticStyle: { "margin-top": "-20px", "margin-left": "12px" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { small: "", tile: "", elevation: "1" },
                  on: { click: _vm.toggleExpand },
                },
                [
                  _vm.isExpanded
                    ? _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-chevron-up"),
                      ])
                    : _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-chevron-down"),
                      ]),
                  _vm._v(" Teile anzeigen "),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-expand-transition", [
            _vm.isExpanded
              ? _c(
                  "div",
                  _vm._l(_vm.assemblySubPositions, function (subPosition) {
                    return _c(
                      "v-card",
                      { key: subPosition.articleId, staticClass: "ma-2" },
                      [
                        _c(
                          "v-card-text",
                          { staticClass: "py-0" },
                          [
                            _c("cart-position-item", {
                              attrs: {
                                position: subPosition,
                                isPartOfAssembly: true,
                                isLoadingSubarticles: _vm.isLoadingSubarticles,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      )
    : _c(
        "v-card",
        { staticClass: "mr-2 mb-3", attrs: { width: "100%" } },
        [
          _c(
            "v-card-text",
            [
              _c("cart-position-item", {
                attrs: {
                  position: _vm.position,
                  isPartOfAssembly: false,
                  isLoadingSubarticles: _vm.isLoadingSubarticles,
                },
              }),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }