var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "pa-3" },
    [
      _c(
        "div",
        { staticStyle: { display: "flex" } },
        [
          _c("router-link", {
            attrs: { to: "/Catalogue", custom: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ navigate }) {
                  return [
                    _c(
                      "v-btn",
                      {
                        staticClass: "blexon-article-button",
                        on: { click: navigate },
                      },
                      [
                        _c("v-icon", [_vm._v("mdi-arrow-left")]),
                        _vm._v(" Zurück "),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm.assembly != null
            ? _c("h2", { staticClass: "pl-3" }, [
                _vm._v(
                  " Baugruppe: " +
                    _vm._s(_vm.assembly.articleId) +
                    " | '" +
                    _vm._s(_vm.assembly.customerArticleName) +
                    "' "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("v-row", [_c("multi-edit")], 1),
      _c(
        "v-row",
        { attrs: { wrap: "" } },
        _vm._l(_vm.subarticles, function (article) {
          return _c(
            "v-col",
            {
              key: article.articleId,
              attrs: {
                cols: "12",
                xs: "12",
                sm: "6",
                md: "4",
                lg: "3",
                xl: "3",
              },
            },
            [
              _c("article-tile", {
                attrs: {
                  articleId: article.articleId,
                  isAssembly: false,
                  isAssemblyView: true,
                },
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }