var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isMultiSelectionMode
    ? _c(
        "div",
        { staticStyle: { display: "flex", margin: "10px", gap: "10px" } },
        [
          _c(
            "v-row",
            { staticClass: "pa-0 ma-0", attrs: { wrap: "", align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "blexon-button",
                      attrs: { block: "" },
                      on: { click: _vm.clearSelection },
                    },
                    [_vm._v(" Auswahl aufheben ")]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { block: "" },
                      on: {
                        click: function ($event) {
                          _vm.articleMultiDeleteDialog = true
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.selectedArticlesCount) +
                          " Artikel löschen "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm.isPowderCoatingAvailable
                ? _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "", color: "rgba(0, 0, 0, 1)" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "div",
                                      _vm._g({}, on),
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { block: "" },
                                            on: {
                                              click: function ($event) {
                                                _vm.isPowdercoatingPossibleDialog = true
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.selectedArticlesCount
                                                ) +
                                                " Artikel pulverbeschichten "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2853190481
                          ),
                        },
                        [
                          _vm._v(
                            " Für alle ausgewählten Artikel eine Pulverbeschichtung hinzufügen, bearbeiten oder entfernen. "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("article-multi-delete-dialog", {
            attrs: { articleMultiDeleteDialog: _vm.articleMultiDeleteDialog },
            on: {
              closeDialog: function ($event) {
                _vm.articleMultiDeleteDialog = false
              },
            },
          }),
          _c("is-powdercoating-possible-dialog", {
            attrs: {
              isPowdercoatingPossibleDialog: _vm.isPowdercoatingPossibleDialog,
              articleIds: _vm.selectedArticleIds,
            },
            on: {
              closeDialog: function ($event) {
                _vm.isPowdercoatingPossibleDialog = false
              },
              openPowdercoatingDialog: function ($event) {
                _vm.isPowdercoatingPossibleDialog = false
                _vm.articleTreatmentEditDialog = true
              },
            },
          }),
          _c("article-treatment-edit-dialog", {
            attrs: {
              articleTreatmentEditDialog: _vm.articleTreatmentEditDialog,
              articles: _vm.selectedArticles,
            },
            on: {
              closeDialog: function ($event) {
                _vm.articleTreatmentEditDialog = false
              },
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }