var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.article
    ? _c("div", { staticClass: "container" }, [
        _c(
          "div",
          [
            _vm.article.state == 3
              ? _c("v-progress-circular", {
                  attrs: { size: "12", indeterminate: "" },
                })
              : _c(
                  "v-icon",
                  { attrs: { dark: "", color: _vm.articleStateColor() } },
                  [_vm._v(" " + _vm._s(_vm.articleStateIcon()) + " ")]
                ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("v-img", {
              attrs: {
                contain: "",
                width: "90px",
                height: "90px",
                src: _vm.thumbnailUrl,
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "item-grow" }, [
          _c("div", { staticClass: "text-subtitle-1" }, [
            _vm._v(_vm._s(_vm.article.customerArticleName)),
          ]),
          _c("div", { staticClass: "text-caption" }, [
            !_vm.article.isAssembly
              ? _c("p", { staticStyle: { "margin-bottom": "0px" } }, [
                  _c("strong", [_vm._v("Material: ")]),
                  _vm._v(_vm._s(_vm.article.materialname)),
                ])
              : _vm._e(),
            _c("p", { staticStyle: { "margin-bottom": "0px" } }, [
              _c("strong", [_vm._v("Blexonnummer: ")]),
              _vm._v(_vm._s(_vm.article.articleId)),
            ]),
            _vm.certificate.length > 0
              ? _c("p", { staticStyle: { "margin-bottom": "0px" } }, [
                  _c("strong", [_vm._v("Zertifikat: ")]),
                  _vm._v(_vm._s(_vm.certificate)),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm.isPartOfAssembly
          ? _c("div", [
              _c("p", { staticClass: "text-subtitle-1" }, [
                _vm._v(_vm._s(_vm.articleQuantity) + " Stk."),
              ]),
            ])
          : _c(
              "div",
              [
                _c("v-text-field", {
                  staticStyle: { width: "100px" },
                  attrs: {
                    placeholder: "Stk.",
                    rules: _vm.allowedCartAmountRule,
                    type: "number",
                    disabled: _vm.isPartOfAssembly,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                  model: {
                    value: _vm.articleQuantity,
                    callback: function ($$v) {
                      _vm.articleQuantity = $$v
                    },
                    expression: "articleQuantity",
                  },
                }),
              ],
              1
            ),
        !_vm.isPartOfAssembly
          ? _c(
              "div",
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-1",
                    attrs: {
                      small: "",
                      title:
                        "Entfernt den Artikel aus dem Warenkorb. Entfernt den Artikel nicht aus Stücklisten.",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.removeFromCart()
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("mdi-close")])],
                  1
                ),
                _vm.selectedSavedCartId > 0
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          small: "",
                          title: "Position zur aktuellen Stückliste hinzufügen",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.addToSavedCart()
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("mdi-playlist-plus")])],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm.isLoadingSubarticles
    ? _c(
        "div",
        { staticClass: "container" },
        [
          _c("v-progress-circular", {
            attrs: { size: "12", indeterminate: "" },
          }),
        ],
        1
      )
    : _c("div", { staticClass: "container" }, [
        _vm._v(" Artikel konnte nicht geladen werden. "),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }