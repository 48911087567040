import { CartItem } from './../dtos/cartItem';
import { ArticleDataDto } from './../dtos/articleDataDto';
import { CustomerOrderDto } from './../dtos/customerOrderDto';
import { CustomerInfoDto } from './../dtos/customerInfoDto';
import { CustomerArticleDto } from './../dtos/customerArticleDto';
import { Cart } from '@/dtos/cart';
import { WerkstoffDto } from '@/dtos/werkstoffDto';
import { OfferDto } from '@/dtos/offerDto';
import { AddressDto } from '@/dtos/addressDto';
import { ManufacturingOfferDto } from '@/dtos/manufacturingOfferDto';
import { MaterialDto } from '@/dtos/materialDto';
import { SavedCartDto } from '@/dtos/savedCartDto';
import { SavedCartPosition } from '@/dtos/savedCartPosition';
import { PendingOrderDto } from '@/dtos/pendingOrderDto';
import { PowderCoatingTreatmentDto } from '@/dtos/powderCoatingTreatmentDto';
import { ArticleState } from '@/dtos/articleState';
import { PriceModel } from '@/dtos/priceModel';
import { OfferFailReason } from '@/dtos/offerFailReason';
import { FertigerAddressDto } from '@/dtos/fertigerAddressDto';

export default {
    // USER
    storeOidcUser(state: any, user: any) {
        state.oidc.user = user;
    },
    storeOidcInstance(state: any, client: any) {
        state.oidc.instance = client;
    },
    setCustomerInfo(state: any, payload: CustomerInfoDto) {
        state.customer = payload;
    },
    updateUserSettings(state: any, data: any) {
        state.customer.user.firstname = data.firstname;
        state.customer.user.lastname = data.lastname;
        state.customer.user.phone = data.phone;
        state.customer.user.newsletterSubscribed = data.newsletterSubscribed;
    },
    updateCustomerSettings(state: any, data: any) {
        state.customer.companyName = data.companyName;
        state.customer.generalPhone = data.phone;
        state.customer.generalEmail = data.email;
        state.customer.defaultAdditionalOrderConfirmationEmail = data.defaultAdditionalOrderConfirmationEmail;
        state.customer.customerMwstNumber = data.customerMwstNumber;
    },
    addOrUpdateCustomerDeliveryAddress(state: any, address: any) {
        const addressIndex = state.customer.deliveryAddresses.findIndex((a:AddressDto) => a.id == address.id);
        if (addressIndex >= 0) {
            state.customer.deliveryAddresses.splice(addressIndex, 1, address);
        } else {
            state.customer.deliveryAddresses.push(address);
        }
    },
    addOrUpdateCustomerInvoiceAddress(state: any, address: any) {
        const addressIndex = state.customer.invoiceAddresses.findIndex((a:AddressDto) => a.id == address.id);
        if (addressIndex >= 0) {
            state.customer.invoiceAddresses.splice(addressIndex, 1, address);
        } else {
            state.customer.invoiceAddresses.push(address);
        }
    },
    setOrderDeliveryAddress(state: any, address: any) {
        const oldDeliverytown = state.currentCart.deliveryAddress.town;
        const oldZip = state.currentCart.deliveryAddress.zip;
        state.currentCart.deliveryAddress = address;
        state.refreshCartAddresses = true;
        if (oldDeliverytown !== state.currentCart.deliveryAddress.town
            || oldZip !== state.currentCart.deliveryAddress.zip){
            state.currentCart.hasChanged = true;
        }
    },
    setOrderInvoiceAddress(state: any, address: any) {
        state.currentCart.invoiceAddress = address;
        state.refreshCartAddresses = true;
    },
    setAddressAsUserPreferred(state: any, addressInfo: any) {
        if (addressInfo.type == 'delivery') {
            state.customer.user.preferredDeliveryAddressId = addressInfo.id;
        } else {
            state.customer.user.preferredInvoiceAddressId = addressInfo.id;
        }
    },
    setManufacturerAdresses(state: any, adresses: FertigerAddressDto[]) {
        state.manufacturerAddresses = adresses;
    },
    setIsAdditionalEmailSelected(state: any, value: boolean) {
        state.isAdditionalEmailSelected = value;
    },
    setAdditionalOrderConfirmationEmail(state: any, value: string) {
        state.additionalOrderConfirmationEmail = value;
    },
    setUsers(state: any, users: any[]) {
        state.users = users;
    },
    setUnseenHintIds(state: any, unseenHintIds: any[]) {
        state.unseenHintIds = unseenHintIds;
    },
    removeUnseenHintId(state: any, hintId: any) {
        const index = state.unseenHintIds.indexOf(hintId);
        if (index > -1) {
            state.unseenHintIds.splice(index, 1);
        }
    },
    // LOADING
    setLoading(state: any, isLoading: boolean) {
        state.loading = isLoading;
    },
    // SNACKBAR
    setShowSnackbar(state: any, show: any) {
        state.showSnackbar = show;
    },
    setSnackbarText(state: any, text: any) {        
        state.snackbar.color = 'accent';
        state.snackbar.articleId = 0;
        state.snackbar.text = text;
    },
    setSnackbarErrorText(state: any, text: any) {
        state.snackbar.color = 'red';
        state.snackbar.articleId = 0;
        state.snackbar.text = text;
    },
    setSnackbarArticleText(state: any, data: any) {
        state.snackbar.color = 'accent';
        state.snackbar.articleId = data.articleId;
        state.snackbar.text = data.text;
    },
    adjustSnackbarTimeout(state: any) {
        let newValue = state.snackbarTimeout % 2 === 0 ? state.snackbarTimeout + 1 : state.snackbarTimeout -1
        state.snackbarTimeout = newValue;
    },

    // BANNER MESSAGES
    setBannerErrorMessage(state: any, message: string) {
        state.bannerErrorMessage = message;
    },
    setBannerInfoMessage(state: any, message: string) {
        state.bannerInfoMessage = message;
    },
    // CATALOGUE
    setCatalogueSearchTerm(state: any, term: string) {
        state.catalogueSearchTerm = term;
    },
    setActiveCatalogueSortTypeValue(state: any, enumValue: number) {
        state.activeCatalogueSortTypeValue = enumValue;
    },
    setCatalogueUserId(state: any, userId: string) {
        state.catalogueUserId = userId;
    },
    // ARTICLES
    addOrUpdateArticles(state: any, articles: CustomerArticleDto[]){
        // Add new articles that weren't in the list before
        // Replace already existing articles to keep them updated
        articles.forEach((a) => {
            const articleIndex = state.articles.findIndex((stateArticle:CustomerArticleDto) => stateArticle.articleId == a.articleId);
            if (articleIndex >= 0){
                state.articles.splice(articleIndex, 1, a);
            } else {
                state.articles.push(a);
            }
        });
    },
    setCatalogueArticles(state: any, payload: any){
        if (payload.reload) {
            state.catalogueArticles = payload.articles
                .map((article: CustomerArticleDto) => article.articleId);            
        } else {
            let newArticleids: number[] = payload.articles
                .filter((newArticle: CustomerArticleDto) => 
                    !(state.catalogueArticles as number[]).some((stateId) => stateId == newArticle.articleId))
                .map((newArticle: CustomerArticleDto) => newArticle.articleId);
            state.catalogueArticles = state.catalogueArticles.concat(newArticleids);
        }
    },
    addCatalogueArticleOnTop(state: any, articleId: number) {
        const index = state.catalogueArticles.findIndex((a:number) => a == articleId);
        if (index >= 0) {
            state.catalogueArticles.splice(index, 1);
        }
        state.catalogueArticles.unshift(articleId);
    },
    setImportingArticles(state: any, articles: CustomerArticleDto[]){
        state.importingArticles = articles;
    },
    addImportingArticle(state: any, article: any){
        state.importingArticles.unshift(article);
    },
    nextTemporaryImportId(state: any) {
        state.temporaryImportId--;
    },
    updateImportingArticle(state: any, payload: any) {
        const index = state.importingArticles.findIndex((a:CustomerArticleDto) => a.articleId == payload.newArticle.articleId);
        const indexOldArticle = state.importingArticles.indexOf(payload.articleToReplace);
        if (index >= 0) {
            // Der Artikel ist schon mit Artikel Id drin. Das ist selten aber möglich.
            // Dann müssen wir den alten Artikel mit negativem Index nur entfernen
            console.log(`Remove importing article at index ${indexOldArticle}`);
            state.importingArticles.splice(indexOldArticle, 1);
        } else {
            // neu einfügen
            console.log(`Replace importing article at index ${indexOldArticle}`);
            state.importingArticles.splice(indexOldArticle, 1, payload.newArticle);
        }
    },
    addOrRemoveSelectedArticleId(state: any, id: number) {
        if (state.selectedArticleIds.includes(id)) {
            state.selectedArticleIds.splice(state.selectedArticleIds.indexOf(id), 1);
        } else {
            state.selectedArticleIds.push(id);
        }
    },
    addOrUpdateArticlePricelists(state: any, priceLists: any) {
        const articleIndex = state.articles.findIndex(
            (a:CustomerArticleDto) => a.articleId === priceLists[0].articleId
        );
        if (articleIndex >= 0){
            state.articles[articleIndex].priceLists = priceLists;
        } else {
            console.log(`Could not find article ${priceLists[0].articleId} to update pricelists.`);
        }
    },

    /**
     * Several articles and assemblies can be selected at once. This
     * clears this selection.
     */
    clearSelectedArticleIds(state: any) {
        state.selectedArticleIds = [];
    },
    
    moveImportingToMain(state:any, article: any) {
        state.articles.push(article);
        state.importingArticles.splice(state.importingArticles.indexOf(article), 1)
        state.catalogueArticles.unshift(article.articleId);
    },
    updateArticle(state: any, article: CustomerArticleDto) {
        // Überprüfen, ob Artikel noch im "Import" Status ist, bzw. ob schon ein Werkstoff ausgewählt wurde
        if (article.werkstoffId <= 0) {
            const index = state.importingArticles.findIndex((a:CustomerArticleDto) => a.articleId == article.articleId);
            if (index >= 0) {
                // ersetzen
                console.log('Replace importing article by signal');
                state.importingArticles.splice(index, 1, article);
            } else {
                // neu einfügen
                console.log('Insert importing article by signal');
                state.importingArticles.unshift(article);
            }
        } else {
            var index = state.importingArticles.findIndex((a:CustomerArticleDto) => a.articleId == article.articleId);
            if (index >= 0) {
                // Hier muss der Artikel raus
                state.importingArticles.splice(index, 1);
            }
            index = state.articles.findIndex((a:CustomerArticleDto) => a.articleId == article.articleId);
            const catalogueIndex = state.catalogueArticles.findIndex((a:number) => a == article.articleId);
            if (index >= 0) {
                // ersetzen
                state.articles.splice(index, 1, article);
                if (catalogueIndex >= 0) {
                    state.catalogueArticles.splice(catalogueIndex, 1, article.articleId)
                }
            } else {
                // neu einfügen
                state.articles.push(article);
                state.catalogueArticles.unshift(article.articleId);
            }
            // If this article is in the cart, invalidate the current offer because it might show wrong information.
            const cartIndex = state.currentCart.cartItems.findIndex((item: CartItem) => item.articleId === article.articleId);
            if (cartIndex >= 0) {
                state.offerInvalidForCartUpdate = true;
                state.currentCart.hasChanged = true
            }
        }
    },
    deleteArticle(state: any, articleId: number) {
        const importIndex = state.importingArticles.findIndex((a:CustomerArticleDto) => a.articleId == articleId);
        const articleIndex = state.articles.findIndex((a:CustomerArticleDto) => a.articleId == articleId);
        const cartIndex = state.currentCart.cartItems.findIndex((c:CartItem) => c.articleId == articleId);
        const catalogueIndex = state.catalogueArticles.findIndex((a:number) => a == articleId);
        if (importIndex >= 0) {
            // Finde und lösche Artikel, wenn in Importing
            state.importingArticles.splice(importIndex, 1);
        } else if (articleIndex >= 0) {
            // Entferne Artikel aus dem Warenkorb, wenn vorhanden
            if (cartIndex >= 0) {
                state.currentCart.cartItems.splice(cartIndex, 1);
                // invalidate potential offers if that happens
                state.offerInvalidForCartUpdate = true;
                state.currentCart.hasChanged = true
            }
            // Finde und lösche Artikel aus Katalog, wenn vorhanden
            if (catalogueIndex >= 0) {
                state.catalogueArticles.splice(catalogueIndex, 1);
            }
            // Finde und lösche Artikel aus der Main-Liste (hier muss er vorhanden sein)
            const removedArticles = state.articles.splice(articleIndex, 1) as CustomerArticleDto[];
            if (removedArticles.length === 1 && removedArticles[0].parentArticleId > 0) {
                // Der Artikel ist ein Subartikel einer Baugruppe.
                // Der Artikel muss auch aus der Baugruppe gelöscht werden. (sonst müsste man die Baugruppe neu laden)
                const parentAssemblyArticle = state.articles.find((a:CustomerArticleDto) => a.articleId == removedArticles[0].parentArticleId) as CustomerArticleDto;
                const indexOfSubArticle = parentAssemblyArticle.subArticleIds.findIndex((id:number) => id == articleId);
                if (indexOfSubArticle >= 0) {
                    parentAssemblyArticle.subArticleIds.splice(indexOfSubArticle, 1);
                }
            }
        } else {
            console.error(`Artikel ${articleId} konnte nicht gefunden und gelöscht werden.`);
        }
    },
    replaceArticle(state:any, articleConstruct: any) {
        const oldArticleId: number = articleConstruct.oldArticleId;
        const newArticle: CustomerArticleDto = articleConstruct.newArticle;
        const articleIndex = state.articles.findIndex((a:CustomerArticleDto) => a.articleId == oldArticleId);
        const cartIndex = state.currentCart.cartItems.findIndex((c:CartItem) => c.articleId == oldArticleId);
        const catalogueIndex = state.catalogueArticles.findIndex((a:number) => a == oldArticleId);
        if (articleIndex >= 0){
            console.log("found and replaced in state articles", oldArticleId, newArticle.articleId);            

            state.articles.splice(articleIndex, 1, newArticle);
        } else {
            state.articles.push(newArticle);
        }
        // Entferne Artikel aus dem Warenkorb, wenn vorhanden
        if (cartIndex >= 0) {
            console.log("found and replaced in cart", oldArticleId, newArticle.articleId);

            state.currentCart.cartItems.splice(cartIndex, 1);
            // invalidate potential offers if that happens
            state.offerInvalidForCartUpdate = true;
            state.currentCart.hasChanged = true
        }
        // Finde und ersetze Artikel aus Katalog, wenn vorhanden
        if (catalogueIndex >= 0) {
            console.log("found and replaced in catalogue", oldArticleId, newArticle.articleId);

            state.catalogueArticles.splice(catalogueIndex, 1, newArticle.articleId);
        }
    },
    setCalculatingMessage(state: any, payload: any) {
        const importIndex = state.importingArticles.findIndex((a:CustomerArticleDto) => a.articleId == payload.articleId);
        const articleIndex = state.articles.findIndex((a:CustomerArticleDto) => a.articleId == payload.articleId);
        if (importIndex >= 0) {
            (state.importingArticles[importIndex] as CustomerArticleDto).calculatingMessage = payload.message;
        } else if (articleIndex >= 0) {
            (state.articles[articleIndex] as CustomerArticleDto).calculatingMessage = payload.message;
        }
    },
    updatePriceStateOfArticle(state: any, payload: any) {
        const articleIndex = state.articles.findIndex((a:CustomerArticleDto) => a.articleId == payload.articleId);
        if (articleIndex >= 0) {
            (state.articles[articleIndex] as CustomerArticleDto).priceState = payload.priceState;
        }
    },
    saveArticleData(state: any, articleData: ArticleDataDto) {
        const articleIndex = state.articles.findIndex((a:CustomerArticleDto) => a.articleId == articleData.articleId);
        const importIndex = state.importingArticles.findIndex((a:CustomerArticleDto) => a.articleId == articleData.articleId);        
        if (articleIndex >= 0) {
            (state.articles[articleIndex] as CustomerArticleDto).customerArticleId = articleData.customerArticleId;
            (state.articles[articleIndex] as CustomerArticleDto).customerArticleName = articleData.customerArticleName;
        } else if (importIndex >= 0) {
            (state.importingArticles[importIndex] as CustomerArticleDto).customerArticleId = articleData.customerArticleId;
            (state.importingArticles[importIndex] as CustomerArticleDto).customerArticleName = articleData.customerArticleName;
        }
    },
    setThicknessOfArticle(state: any, payload: any) {
        const articleIndex = state.articles.findIndex((a:CustomerArticleDto) => a.articleId == payload.articleId);
        const importArticleIndex = state.importingArticles.findIndex((a:CustomerArticleDto) => a.articleId == payload.articleId);
        if (importArticleIndex >= 0) {
            (state.importingArticles[importArticleIndex] as CustomerArticleDto).thickness = payload.thickness;
        } else if (articleIndex >= 0) {
            (state.articles[articleIndex] as CustomerArticleDto).thickness = payload.thickness;
        }
    },
    setArticleStateToLocked(state: any, articleId: any) {
        const articleIndex = state.articles.findIndex((a:CustomerArticleDto) => a.articleId == articleId);
        if (articleIndex >= 0) {
            (state.articles[articleIndex] as CustomerArticleDto).locked = true;
        }
    },
    setArticleStateToUnlocked(state: any, articleId: any) {
        const articleIndex = state.articles.findIndex((a:CustomerArticleDto) => a.articleId == articleId);
        if (articleIndex >= 0) {
            (state.articles[articleIndex] as CustomerArticleDto).locked = false;
        }
    },
    setArticleStateToCalculating(state: any, articleId: any) {
        const articleIndex = state.articles.findIndex((a:CustomerArticleDto) => a.articleId == articleId);
        if (articleIndex >= 0) {
            (state.articles[articleIndex] as CustomerArticleDto).calculating = true;
        }
    },
    setFileSizeLimits(state: any, fileSizeLimits: any) {
        state.fileSizeLimits = fileSizeLimits;
    },
    setPowders(state: any, powders: any) {
        state.powders = powders;
    },
    addOrUpdateTreatmentForArticle(state: any, treatment: PowderCoatingTreatmentDto) {
        const articleIndex = state.articles.findIndex((a:CustomerArticleDto) => a.articleId == treatment.articleId);
        if (articleIndex >= 0) {
            (state.articles[articleIndex] as CustomerArticleDto).powderCoatingTreatment = treatment;
        }
    },
    deleteTreatmentForArticle(state: any, articleId: any) {
        const articleIndex = state.articles.findIndex((a:CustomerArticleDto) => a.articleId == articleId);
        if (articleIndex >= 0) {
            (state.articles[articleIndex] as CustomerArticleDto).powderCoatingTreatment = undefined;
        }
    },
    setHasMoreArticlesToLoad(state: any, hasMoreToLoad: boolean) {
        state.hasMoreArticlesToLoad = hasMoreToLoad;
    },
    setArticleCalculatingPollingTimerId(state: any, id: number) {
        state.articleCalculatingPollingTimerId = id;
    },


    // CART
    initializeCart(state: any, userAddresses: any){
        let userDelivery = userAddresses.delivery;
        let userInvoice = userAddresses.invoice;
        let newCart = new Cart();
        newCart.deliveryAddress = new AddressDto();
        newCart.deliveryAddress.id = userDelivery.id;
        newCart.deliveryAddress.givenName = userDelivery.givenName;
        newCart.deliveryAddress.surname = userDelivery.surname;
        newCart.deliveryAddress.companyName = userDelivery.companyName;
        newCart.deliveryAddress.street = userDelivery.street;
        newCart.deliveryAddress.zip = userDelivery.zip;
        newCart.deliveryAddress.town = userDelivery.town;
        newCart.deliveryAddress.phone = userDelivery.phone;
        newCart.deliveryAddress.eMail = '';
        newCart.deliveryAddress.customerId = userDelivery.customerId;
        newCart.deliveryAddress.country = userDelivery.country;
        newCart.deliveryAddress.type = userDelivery.type;
        newCart.deliveryAddress.isBilledDigitally = true;
        newCart.deliveryAddress.isDefault = userDelivery.isDefault;

        newCart.invoiceAddress = new AddressDto();
        newCart.invoiceAddress.id = userInvoice.id;
        newCart.invoiceAddress.givenName = userInvoice.givenName;
        newCart.invoiceAddress.surname = userInvoice.surname;
        newCart.invoiceAddress.companyName = userInvoice.companyName;
        newCart.invoiceAddress.street = userInvoice.street;
        newCart.invoiceAddress.zip = userInvoice.zip;
        newCart.invoiceAddress.town = userInvoice.town;
        newCart.invoiceAddress.phone = '';
        newCart.invoiceAddress.eMail = userInvoice.eMail;
        newCart.invoiceAddress.customerId = userInvoice.customerId;
        newCart.invoiceAddress.country = userInvoice.country;
        newCart.invoiceAddress.type = userInvoice.type;
        newCart.invoiceAddress.isBilledDigitally = true;
        newCart.invoiceAddress.isDefault = userInvoice.isDefault;
        newCart.hasChanged = true;
        newCart.cartItems = new Array<CartItem>();
        state.currentCart = newCart;
    },
    emptyCart(state:any){
        state.currentCart.hasChanged = true;
        state.currentCart.cartItems = new Array<CartItem>();
    },

    addToCart(state: any, newItem: CartItem) {
        let itemAlreadyInCart = state.currentCart.cartItems
            .find((item: CartItem) => item.articleId === newItem.articleId);
        if (itemAlreadyInCart === undefined) {
            state.currentCart.cartItems.push(newItem);
        } else {
            state.currentCart.cartItems.splice(
                state.currentCart.cartItems.indexOf(itemAlreadyInCart),
                1,
                newItem);
        }
        state.currentCart.hasChanged = true;
    },
    removeArticleFromCart(state: any, articleId: number){
        const cartIndex = state.currentCart.cartItems
            .findIndex((item:CartItem) => item.articleId == articleId);
        if(cartIndex >= 0) {
            state.currentCart.cartItems.splice(cartIndex, 1);
            state.currentCart.hasChanged = true;
        } else {
            console.log(`Artikel ${articleId} konnte nicht im Warenkorb gefunden werden.`);   
        }
    },
    setAmountOfCartItem(state: any, payload: any){
        (state.currentCart.cartItems
            .find((item: CartItem) => item.articleId === payload.articleId).quantity as number) = payload.quantity;
        state.currentCart.hasChanged = true;
    },
    setRefreshCardAddressesToggle(state: any, value: any) {
        state.refreshCartAddresses = value;
    },
    // SAVED CARTS
    setSavedCarts(state: any, carts: SavedCartDto[]) {
        state.savedCarts = carts;
    },
    saveNewSavedCart(state: any, cart: SavedCartDto) {
        state.savedCarts.splice(0, 0, cart);
    },
    deleteSavedCart(state: any, cartId: number) {
        const index = state.savedCarts
            .findIndex((cart:SavedCartDto) => cart.id == cartId);
        if (index >= 0) {
            state.savedCarts.splice(index, 1);
        }
    },
    setAmountOfSavedCartItem(state: any, data: any){
        const index = state.savedCarts.findIndex((cart:SavedCartDto) => cart.id == data.cartId);
        if (index >= 0) {
            const articleIndex = state.savedCarts[index].positions
                .findIndex((item: SavedCartPosition) => item.articleId === data.articleId);
            if (articleIndex >= 0) {
                state.savedCarts[index].positions[articleIndex].quantity = data.quantity;
            }
        }
    },
    removeArticleFromSavedCart(state: any, data: any) {
        const index = state.savedCarts.findIndex((cart:SavedCartDto) => cart.id == data.cartId);
        if (index >= 0) {
            const articleIndex = state.savedCarts[index].positions
                .findIndex((item: SavedCartPosition) => item.articleId === data.articleId);
            if (articleIndex >= 0) {
                state.savedCarts[index].positions.splice(articleIndex, 1);
            }
        }
    },
    addArticleToSavedCart(state: any, data: any) {
        const index = state.savedCarts.findIndex((cart:SavedCartDto) => cart.id == data.cartId);
        if (index >= 0) {
            var positions = state.savedCarts[index].positions;
            const articleIndex = positions
                .findIndex((item: SavedCartPosition) => item.articleId === data.articleId);
            if (articleIndex < 0) {
                var positionNr = 0;
                if (positions.length > 0) {
                    positionNr = positions[positions.length - 1].positionNr;
                }
                // Artikel hinzufügen
                positions.push({
                    articleId: data.articleId,
                    quantity: data.quantity,
                    position: positionNr,
                    customerArticleName: data.customerArticleName,
                });
            }
        }
    },
    setCurrentSavedCartId(state: any, id: any) {
        state.currentSavedCartId = id;
    },
    // WERKSTOFFE
    setWerkstoffe(state: any, werkstoffe: WerkstoffDto[]) {
        state.werkstoffe = werkstoffe;
    },
    // FEATURES
    setPowderCoatingAvailability(state: any, isAvailable: boolean) {
        state.isPowderCoatingAvailable = isAvailable;
    },
    // ORDERS
    setOrders(state: any, payload: any){
        // Add new orders that weren't in the list before
        // Replace already existing orders to keep them updated
        if (payload.reload) {
            state.orders = payload.orders;
        } else {
            payload.orders.forEach((o:CustomerOrderDto) => {
                const orderIndex = state.orders.findIndex((order:CustomerOrderDto) => order.orderId == o.orderId);
                if (orderIndex >= 0){
                    state.orders.splice(orderIndex, 1, o);
                } else {
                    state.orders.push(o);
                }
            });
        }
    },
    setOrderSuccessful(state: any, successful: boolean){
        state.orderSuccessful = successful;
    },
    setManualOfferRequested(state: any, requested: boolean){
        state.manualOfferRequested = requested;
    },
    setCurrentOfferCalculating(state: any, value: boolean) {
        state.currentOfferCalculating = value;
    },
    setOrderCancellations(state: any, cancellations: any) {
        state.orderCancellations = cancellations
    },
    addOrUpdateCancellation(state: any, cancellation: any) {
        const cancellationIndex = state.orderCancellations.findIndex((c:any) => c.blexonOrderId == cancellation.blexonOrderId);
        if (cancellationIndex >= 0){
            state.orderCancellations.splice(cancellationIndex, 1, cancellation);
        } else {
            state.orderCancellations.push(cancellation);
        }
    },
    // OFFER
    setCurrentOfferToNull(state: any){
        state.offer = null;
    },
    updateOffer(state: any, newOffer: OfferDto){
        let newDeliveryDate: Date = new Date();
        let defaultManufacturer: ManufacturingOfferDto | undefined = newOffer.manufacturingOffers
            .find((offer: any) => offer.manufacturerId == newOffer.selectedManufacturerId);
        if(defaultManufacturer !== undefined) {
            newDeliveryDate = defaultManufacturer.datesOfDelivery[0];
        }
        let newClientOffer = new OfferDto();
        newClientOffer.calculationId = newOffer.calculationId
        newClientOffer.manufacturingOffers = newOffer.manufacturingOffers;
        newClientOffer.orderDiscounts = newOffer.orderDiscounts;
        newClientOffer.selectedManufacturerId = newOffer.selectedManufacturerId;
        newClientOffer.deliveryType = newOffer.deliveryType;
        newClientOffer.deliveryDate = newDeliveryDate;
        newClientOffer.customKommissionsnummer = '';
        newClientOffer.deliveryChoice = 'delivery';
        newClientOffer.defaultManufacturerId = defaultManufacturer?.manufacturerId;
        newClientOffer.totalOfAllArticlesInCart = newOffer.totalOfAllArticlesInCart;
        state.offer = newClientOffer;
        state.currentCart.hasChanged = false;
        state.offerValidTimerSeconds = state.offerValidTimerTotalSeconds;
    },
    setOfferFailed(state: any, hasFailed: boolean){
        state.offerFailed = hasFailed;
    },
    setOfferFailReason(state: any, reason: OfferFailReason){
        state.offerFailReason = reason;
    },
    setOfferDeliveryDate(state: any, newDate: Date){
        state.offer.deliveryDate = newDate;
    },
    setOfferDeliveryChoice(state: any, newType: string){
        state.offer.deliveryChoice = newType;
    },
    setCustomKommissionsnummer(state: any, kommissionsNummer: string){
        state.offer.customKommissionsnummer = kommissionsNummer;
    },
    setOfferInvalidForCartUpdate(state: any, value: boolean){
        state.offerInvalidForCartUpdate = value;
    },
    restartOfferValidTimer(state: any){
        state.offerValidTimerSeconds = state.offerValidTimerTotalSeconds;
    },
    decrementOfferValidTimer(state: any){
        if(state.offerValidTimerSeconds > 0){
            state.offerValidTimerSeconds--;
        }
    },
    setOfferDecrementTimerId(state: any, newId: number) {
        state.offerDecrementTimerId = newId;
    },
    changeSelectedManufacturer(state: any, manufacturerId: any){
        state.offer.selectedManufacturerId = manufacturerId;
        state.offer.deliveryDate = state.offer.manufacturingOffers
            .find((offer: any) => offer.manufacturerId == state.offer.selectedManufacturerId)?.datesOfDelivery[0];
    },
    setPendingOrders(state: any, orders: any) {
        for (const order of orders) {
            order.isInCart = false;
            if (state.pendingOrders.find((o: PendingOrderDto) => o.id == order.id) == undefined) {
                state.pendingOrders.push(order);
            }
        }
    },
    removePendingOrder(state: any, orderId: any) {
        var index = state.pendingOrders.indexOf((p: PendingOrderDto) => p.id == orderId);
        state.pendingOrders.splice(index, 1);
    },
    setCurrentPriceModel(state: any, model: PriceModel) {
        state.currentPriceModel = model;
        state.currentCart.hasChanged = true;
    },
    setAcceptedAGB(state: any, accepted: boolean) {
        state.acceptedAGB = accepted;
    },
    setAcceptedNachAufwand(state: any, accepted: boolean) {
        state.acceptedNachAufwand = accepted;
    },
    setRampenabholungSelectionValid(state: any, value: boolean) {
        state.rampenabholungSelectionValid = value;
    },
};
