
import { AssemblyInfoDto } from '@/dtos/assemblyInfoDto';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import httpClient from '../../store/httpClient';

@Component
export default class ArticleDeleteWarningDialog extends Vue {
	@Prop()
	private articleDeleteWarningDialog!: boolean;
	@Prop()
	private articleId!: number;
	@Prop()
	private articleCartNames!: string[];
	@Prop()
	private assemblyInfo!: AssemblyInfoDto;
	@Prop()
	private isAssemblyView!: boolean;

	private isArticleWeldedToOthers: boolean | null = null;
	private isDeleting: boolean = false;

	private get isInitializing() {
		return this.isArticleWeldedToOthers === null;
	}

	@Watch("articleDeleteWarningDialog")
	private initialize() {
		if (this.articleDeleteWarningDialog) {
			if (!this.isAssemblyView || !this.assemblyInfo || this.assemblyInfo.weldedSubarticles.length <= 0) {
				this.isArticleWeldedToOthers = false;
			} else {
				this.isArticleWeldedToOthers = this.assemblyInfo.weldedSubarticles.some(sa => sa.subarticleId === this.articleId);
			}
		}
	}

	private async deleteArticle() {
        this.$emit('setDeleteOverlay', true);
		this.isDeleting = true;
        try {
            const response = await httpClient().post(`article/deleteArticle?articleId=${this.articleId}&force=true`);
            Vue.nextTick(() => {                        
                this.$emit('setDeleteOverlay', false);
				let data = {
					articleId: this.articleId,
					refreshCart: true,
				}
                this.$store.dispatch('deleteArticle', data);
                console.info(`Artikel ${this.articleId} wurde gelöscht`);
            });
        } catch(err) {
            console.error(`Fehler beim Löschen des Artikels. ${err}`);
            this.$store.dispatch('setSnackbarErrorText', 'Artikel konnte nicht gelöscht werden.');
        } finally {
			this.isDeleting = false;
			this.closeDialog();
		}
    }

	private closeDialog() {
		this.$emit('closeDialog');
	}

}

