
import { Component, Vue, Prop } from "vue-property-decorator";
import httpClient from "@/store/httpClient";
import { CustomerArticleDto } from "@/dtos/customerArticleDto";

@Component({
	components: {},
})
export default class ArticleTileHeader extends Vue {
	@Prop()
	private article!: CustomerArticleDto;
	@Prop()
	private isAssembly!: boolean;
	// This overrides the calculated isMultiSelectionMode()
	// Needed so that there is no hover over checkbox when viewing an assembly
	@Prop()
	private isAssemblyView!: boolean;
	@Prop()
	private hover!: boolean;
	@Prop()
	private isLocked!: boolean;
	@Prop()
	private isCalculating!: boolean;
	@Prop()
	private isArticleLockedAndNotAdmin!: boolean;
	@Prop()
	private isArticleUnlockedAndAdmin!: boolean;
	@Prop()
	private isArticleLockedAndAdmin!: boolean;

	private get isMultiSelectionMode() {
		return this.$store.state.selectedArticleIds.length > 0;
	}

	private openArticleEditDialog() {
		this.$emit("openArticleEditDialog");
	}

	private checkDeleteArticle() {
		this.$emit("checkDeleteArticle");
	}

	private async lockArticle() {
        try {
            await httpClient().post(`article/LockArticle?articleId=${this.article.articleId}`);
            this.$store.commit('setArticleStateToLocked', this.article.articleId);
        } catch(err) {
            console.log(`Der Artikel konnte nicht gesperrt werden. Error: ${err}`);
        }
    }

	private async unlockArticle() {
        try {
            await httpClient().post(`article/UnlockArticle?articleId=${this.article.articleId}`);
            this.$store.commit('setArticleStateToUnlocked', this.article.articleId);
        } catch(err) {
            console.log(`Der Artikel konnte nicht entsperrt werden. Error: ${err}`);
        }
    }

	private get isArticleSelected() {
        return this.$store.state.selectedArticleIds.includes(this.article.articleId);
    }
    private set isArticleSelected(newValue: boolean) {
        // the new value is irrelevant. We just toggle the checkbox.
        this.$store.commit('addOrRemoveSelectedArticleId', this.article.articleId);
    }

}
