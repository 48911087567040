
import { Component, Vue, Prop } from "vue-property-decorator";
import { CartItem } from "@/dtos/cartItem";
import { SavedCartDto } from "@/dtos/savedCartDto";
import CartPosition from "./CartPosition.vue";

@Component({
    components: {
		CartPosition
    }
})
export default class CurrentCart extends Vue {
	private saveAsNewCartDialog: boolean = false;
	private newCartName: string = '';

	private get hasItemsInCart() {
		if (this.$store.state.currentCart === null) {
			return false;
		}
		return this.$store.state.currentCart.cartItems.length > 0;
	}

	private get cartPositions(): Array<CartItem> {		
		return this.$store.state.currentCart.cartItems;
    }

	private get saveCartValid(): boolean {
        if (this.$store.state.currentCart.cartItems.length <= 0) return false;
        if (this.newCartName.length <= 0) return false;
        return !this.cartNameAlreadyExists;
    }

	private get cartNameAlreadyExists(): boolean {
        if (this.newCartName.length > 0) {
            return this.$store.state.savedCarts
                .some((saved: SavedCartDto) => saved.name.trim().toLowerCase() === this.newCartName.trim().toLowerCase());
        }
        return false;        
    }

	private checkNewCartName() {
		if (this.cartNameAlreadyExists) {
			return 'Eine Stückliste mit diesem Namen existiert bereits.'
		}
		return true;
	}

	private emptyCart() {
        this.$store.dispatch('emptyCart');
    }

	private async saveNewCart() {
        let newCart: SavedCartDto = new SavedCartDto();
        newCart.id = 0;
        newCart.name = this.newCartName;
		newCart.positions = [];
		this.cartPositions.forEach((a: CartItem) => {
			let article = this.$store.getters.articleById(a.articleId);
			newCart.positions.push({
				position: 0,
				quantity: a.quantity,
				articleId: a.articleId,
				customerArticleId: article.customerArticleId,
				customerArticleName: article.customerArticleName,
			})
		});
		this.saveAsNewCartDialog = false;
        await this.$store.dispatch('saveNewSavedCart', newCart);
		this.newCartName = '';
		this.$store.dispatch('setSnackbarText', 'Stückliste wurde gespeichert.');
    }

	private checkEscOrEnter(keyPress: KeyboardEvent) {
		if (keyPress.key === 'Escape') {
			this.saveAsNewCartDialog = false;
		} else if (keyPress.key === 'Enter') {
			if (this.saveCartValid) this.saveNewCart();
		}
	}

}
