var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.loading
        ? _c("v-progress-linear", {
            attrs: { indeterminate: "", color: "accent" },
          })
        : _vm._e(),
      !_vm.loading
        ? _c(
            "v-form",
            {
              ref: "form",
              model: {
                value: _vm.formValid,
                callback: function ($$v) {
                  _vm.formValid = $$v
                },
                expression: "formValid",
              },
            },
            [
              _c(
                "v-container",
                { attrs: { fluid: "", "pa-0": "", "ma-0": "" } },
                [
                  _c("h1", { staticClass: "font-weight-light" }, [
                    _vm._v(
                      "Beanstandung zu Auftrag " + _vm._s(_vm.ticketDto.orderId)
                    ),
                  ]),
                  _c(
                    "v-stepper",
                    {
                      model: {
                        value: _vm.currentStep,
                        callback: function ($$v) {
                          _vm.currentStep = $$v
                        },
                        expression: "currentStep",
                      },
                    },
                    [
                      _c(
                        "v-stepper-header",
                        [
                          _c(
                            "v-stepper-step",
                            {
                              attrs: {
                                color: "accent",
                                complete: _vm.currentStep > 1,
                                step: "1",
                                editable: true,
                              },
                            },
                            [_vm._v(" Positionen auswählen ")]
                          ),
                          _vm._l(
                            _vm.selectedPositions,
                            function (complaint, index) {
                              return [
                                _c("v-divider", { key: "d" + index }),
                                _c(
                                  "v-stepper-step",
                                  {
                                    key: index,
                                    attrs: {
                                      color: complaint.valid
                                        ? "accent"
                                        : "error",
                                      complete: _vm.currentStep > index + 2,
                                      editable: true,
                                      step: index + 2,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.selectedPositions.length > 2
                                            ? "Pos"
                                            : "Position"
                                        ) +
                                        " " +
                                        _vm._s(complaint.position) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            }
                          ),
                          _c("v-divider"),
                          _c(
                            "v-stepper-step",
                            {
                              attrs: {
                                complete:
                                  _vm.currentStep >
                                  _vm.selectedPositions.length + 2,
                                step: _vm.selectedPositions.length + 2,
                                editable: _vm.canWeiter(),
                                color: _vm.formValid ? "accent" : "error",
                              },
                            },
                            [_vm._v(" Kontaktdaten ")]
                          ),
                          _c("v-divider"),
                          _c(
                            "v-stepper-step",
                            {
                              attrs: {
                                step: _vm.selectedPositions.length + 3,
                                color: "accent",
                                editable: _vm.canWeiter(),
                              },
                            },
                            [_vm._v(" Bestätigung ")]
                          ),
                        ],
                        2
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "1" } },
                        [
                          _c("choose-position", {
                            attrs: {
                              positions: _vm.ticketDto.complains,
                              ticketClosed: _vm.ticketBlocked,
                            },
                            on: {
                              changed: function ($event) {
                                _vm.hasChanges = true
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._l(_vm.selectedPositions, function (position, index) {
                        return _c(
                          "v-stepper-content",
                          {
                            key: position.position,
                            attrs: { step: index + 2 },
                          },
                          [
                            !_vm.loading
                              ? _c("edit-ticket-position-component", {
                                  attrs: {
                                    complaint: position,
                                    ticket: _vm.ticketDto,
                                  },
                                  on: {
                                    validChanged: function ($event) {
                                      position.valid = $event
                                    },
                                    changed: function ($event) {
                                      _vm.hasChanges = true
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: _vm.selectedPositions.length + 2 } },
                        [
                          _c(
                            "div",
                            [
                              _c("p", [
                                _vm._v(" Wir senden Ihnen eine E-Mail an "),
                                _c("b", [
                                  _vm._v(_vm._s(_vm.ticketDto.customerEmail)),
                                ]),
                                _vm._v(
                                  ", sobald sich der Status dieser Beanstandung ändert. Bei Rückfragen rufen wir Sie unter "
                                ),
                                _c("b", [
                                  _vm._v(_vm._s(_vm.ticketDto.customerPhone)),
                                ]),
                                _vm._v(" an. "),
                              ]),
                              _c("p", [
                                _vm._v(
                                  " Falls Sie für die Bearbeitung dieser Beanstandung anders kontaktiert werden möchten, können Sie alternative Kontaktdaten angeben: "
                                ),
                              ]),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mx-0 blexon-button",
                                  attrs: { disabled: _vm.ticketBlocked },
                                  on: {
                                    click: function ($event) {
                                      _vm.ticketDto.useAlternativeContactInfo =
                                        !_vm.ticketDto.useAlternativeContactInfo
                                      _vm.hasChanges = true
                                      _vm.validateForm()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.altContactButtonText) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.ticketDto.useAlternativeContactInfo,
                                      expression:
                                        "ticketDto.useAlternativeContactInfo",
                                    },
                                  ],
                                  staticClass: "mt-5",
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Alternative E-Mail Adresse",
                                      color: "accent",
                                      rules: _vm.requiredAltContactEmailRule,
                                      disabled: _vm.ticketBlocked,
                                    },
                                    on: {
                                      input: function ($event) {
                                        _vm.validateForm()
                                        _vm.hasChanges = true
                                      },
                                      blur: function ($event) {
                                        _vm.ticketDto.alternativeEmail =
                                          _vm.ticketDto.alternativeEmail.trim()
                                      },
                                    },
                                    model: {
                                      value: _vm.ticketDto.alternativeEmail,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ticketDto,
                                          "alternativeEmail",
                                          $$v
                                        )
                                      },
                                      expression: "ticketDto.alternativeEmail",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Alternative Telefonnummer",
                                      color: "accent",
                                      rules: _vm.requiredAltContactRule,
                                      disabled: _vm.ticketBlocked,
                                    },
                                    on: {
                                      input: function ($event) {
                                        _vm.validateForm()
                                        _vm.hasChanges = true
                                      },
                                      blur: function ($event) {
                                        _vm.ticketDto.alternativePhone =
                                          _vm.ticketDto.alternativePhone.trim()
                                      },
                                    },
                                    model: {
                                      value: _vm.ticketDto.alternativePhone,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.ticketDto,
                                          "alternativePhone",
                                          $$v
                                        )
                                      },
                                      expression: "ticketDto.alternativePhone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: _vm.selectedPositions.length + 3 } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { column: "" } },
                            [
                              !_vm.canWeiter() && !_vm.ticketBlocked
                                ? _c("v-flex", [
                                    _c("p", [
                                      _vm._v(
                                        " Bitte ergänzen Sie die fehlenden Angaben, um das Ticket zu erstellen. "
                                      ),
                                    ]),
                                  ])
                                : _c(
                                    "v-flex",
                                    [
                                      _c(
                                        "v-simple-table",
                                        [
                                          [
                                            _c("thead", [
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  {
                                                    staticClass: "text-right",
                                                    staticStyle: {
                                                      "max-width": "50px",
                                                    },
                                                  },
                                                  [_vm._v("Position")]
                                                ),
                                                _c(
                                                  "th",
                                                  {
                                                    staticStyle: {
                                                      "max-width": "120px",
                                                    },
                                                  },
                                                  [_vm._v("Bild")]
                                                ),
                                                _c("th", [_vm._v("Blexon Id")]),
                                                _c("th", [
                                                  _vm._v("# Beanstandete"),
                                                ]),
                                                _c("th", [_vm._v("Name")]),
                                                _c("th", [
                                                  _vm._v("Beanstandung"),
                                                ]),
                                                _c("th", [_vm._v("Erwartung")]),
                                              ]),
                                            ]),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.selectedPositions,
                                                function (position, index) {
                                                  return _c(
                                                    "tr",
                                                    { key: index },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "text-right",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                position.position
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        [
                                                          _c("v-img", {
                                                            staticStyle: {
                                                              margin: "5px",
                                                            },
                                                            attrs: {
                                                              contain: "",
                                                              "max-height":
                                                                "120px",
                                                              "max-width":
                                                                "120px",
                                                              src: _vm.getThumbnailUrl(
                                                                position.part
                                                                  .articleId
                                                              ),
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c("td", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              position.part
                                                                .articleId
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              position.numberOfWrongParts
                                                            ) +
                                                            " / " +
                                                            _vm._s(
                                                              position.part
                                                                .menge
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              position.part
                                                                .description
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.getComplainType(
                                                                position.complainType
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.getExpectationDescription(
                                                                position.expectationType,
                                                                position.expectation
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ],
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                              _c("v-flex", { attrs: { "mt-5": "" } }, [
                                _c("p", { staticClass: "title" }, [
                                  _vm._v(" Korrespondenz an: "),
                                ]),
                              ]),
                              _vm.ticketDto.useAlternativeContactInfo
                                ? _c("v-flex", [
                                    _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.ticketDto.alternativeEmail
                                          ) +
                                          " | " +
                                          _vm._s(
                                            _vm.ticketDto.alternativePhone
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ])
                                : _c("v-flex", [
                                    _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.ticketDto.customerEmail) +
                                          " | " +
                                          _vm._s(_vm.ticketDto.customerPhone) +
                                          " "
                                      ),
                                    ]),
                                  ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.loading
        ? _c(
            "v-container",
            { staticClass: "pa-0", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.currentStep > 1,
                              expression: "currentStep > 1",
                            },
                          ],
                          staticClass: "mx-0 blexon-button",
                          on: {
                            click: function ($event) {
                              _vm.currentStep--
                            },
                          },
                        },
                        [_vm._v(" Zurück ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { align: "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-0 blexon-button",
                          on: {
                            click: function ($event) {
                              return _vm.close()
                            },
                          },
                        },
                        [_vm._v(" Abbrechen ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { align: "right" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { column: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { shrink: "" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mx-0 blexon-button",
                                  attrs: {
                                    disabled: !_vm.canWeiter() || _vm.saving,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.weiter()
                                    },
                                  },
                                },
                                [
                                  _vm.saving
                                    ? _c("v-progress-circular", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          indeterminate: "",
                                          size: "20",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.currentStep <
                                  _vm.selectedPositions.length + 3
                                    ? _c("span", [_vm._v("Weiter")])
                                    : _c("span", [_vm._v("Abschliessen")]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.ticketBlocked
                            ? _c("v-flex", { attrs: { shrink: "" } }, [
                                _vm._v(
                                  " Die Beanstandung ist bereits verarbeitet. Wenden Sie sich an info@blexon.com für weitere Unterstützung. "
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500", persistent: "" },
          model: {
            value: _vm.seiteVerlassenDialog,
            callback: function ($$v) {
              _vm.seiteVerlassenDialog = $$v
            },
            expression: "seiteVerlassenDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_c("b", [_vm._v("Datenverlust Warnung")])]),
              _c("v-card-text", [
                _vm._v(" Änderungen werden beim Verlassen verworfen. "),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.seiteVerlassenDialog = false
                          _vm.next(false)
                        },
                      },
                    },
                    [_vm._v(" Abbrechen ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "blexon-button",
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.next()
                        },
                      },
                    },
                    [_vm._v(" Ok ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }