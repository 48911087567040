
import { Component, Prop, Vue } from 'vue-property-decorator';
import rules from '@/store/rules';
import { ArticleState } from '@/dtos/articleState';
import { CartItem } from '@/dtos/cartItem';

@Component({})
export default class CartPositionItem extends Vue {
	@Prop()
	private position!: CartItem;
	@Prop()
	private isPartOfAssembly!: boolean;
	@Prop()
	private isLoadingSubarticles!: boolean;
	private allowedCartAmountRule = rules.allowedCartAmountRule;

    private get article() {
		const stateArticle = this.$store.getters.articleById(this.position.articleId);
        return stateArticle;
    }

    private get articleQuantity() {
		if (this.isPartOfAssembly) {
			return this.article.quantityInAssembly * this.position.quantity;
		} else {
			return this.position.quantity;
		}
    }
    private set articleQuantity(quantity: any) {
        const parsed: number = parseInt(quantity);
        if (quantity !== undefined && !isNaN(parsed) && parsed > 0) {
            this.$store.dispatch('setAmountOfCartItem', {
                articleId: this.position.articleId,
                quantity: parsed
            });
        }
    }

	private get certificate() : string {
        return this.article.certificateTypes > 0 ? "3.1" : ""
    }

	private get selectedSavedCartId(): number {
		return this.$store.state.currentSavedCartId;
	}

    private removeFromCart() {
        this.$store.dispatch('removeArticleFromCart', this.position.articleId);
    }

	private addToSavedCart() {
		this.$store.dispatch("addArticleToSavedCart", {
			amount: this.articleQuantity,
			articleId: this.position.articleId,
            customerArticleName: this.article.customerArticleName,
		});
	}

    private get thumbnailUrl() {
        return this.$store.getters.getThumbnailUrl(this.position.articleId, undefined);
    }

    private articleStateIcon() {
        switch(this.article.state) { 
            case ArticleState.OK: { 
                return 'mdi-check-circle';
            } 
            case ArticleState.Warning: { 
                return 'mdi-alert-circle';
            } 
            default: { 
                return 'mdi-close-circle';
            } 
        }     
    }

    private articleStateColor() {
        switch(this.article.state) { 
            case ArticleState.OK: { 
                return '#97b498';
            } 
            case ArticleState.Warning: { 
                return '#cbc693';
            } 
            default: { 
                return '#cb9ca1';
            } 
        }     
    }

}

