import { CustomerInfoDto } from './../dtos/customerInfoDto';
import { PowderCoatingTreatmentDto } from '@/dtos/powderCoatingTreatmentDto';
import { CustomerArticleDto } from './../dtos/customerArticleDto';
import { UserManager} from 'oidc-client';
import httpClient from './httpClient';
import * as signalR from '@microsoft/signalr';
import { SavedCartDto } from '@/dtos/savedCartDto';
import { PriceState } from '@/dtos/priceState';
import { SavedCartPosition } from '@/dtos/savedCartPosition';
import { CartItem } from '@/dtos/cartItem';
import { Cart } from '@/dtos/cart';

export default {
    async bootstrap(context: any) {
        return new Promise<void>((resolve, reject) => {
            console.log('start bootstrap application');
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            var signInUser = urlParams.get('signInUser');
            if (signInUser != null) {
                // Der Benutzer soll automatisch eingeloggt werden.
                // Dazu speichern wir den Logincode, damit wir den noch haben,
                // nach dem Logout. Das Logout macht ein redirect, was
                // sich leider nicht verhindern lässt.
                var url = process.env.VUE_APP_KUNDENPORTAL_URL;
                url = url?.substr(0, url.length - 3);
                window.sessionStorage.setItem('loginCodeUrl', `${url}?loginCode=${encodeURIComponent(signInUser)}`);
                var client = new UserManager(context.state.oidc.config);
                context.commit('storeOidcInstance', client);
                context.state.oidc.instance.signoutRedirect();
                resolve();
            }
            var loginCode = urlParams.get('loginCode');
            if (loginCode != null) {
                // Nun wurden wir nach dem Ausloggen auf logout.html weitergeleitet
                // und von dort wieder hierher. Da ein LoginCode gespeichert war,
                // wird der hier mitgeschickt.
                context.state.oidc.config.display = loginCode;
                // Mit dem LoginCode im der Displayeigenschaft, macht der identity server
                // ein sofortiges Login.
            }
            var client = new UserManager(context.state.oidc.config);
            // Add event when token expires. This is just for monitoring
            // at the beginning. Can be removed later.
            client.events.addAccessTokenExpiring(function(){
                console.log("shop token expiring...");
            });
            context.commit('storeOidcInstance', client);
            console.log('checking user authentication');
            context.state.oidc.instance.getUser().then((user: any) => {
                if (user === null || user.expired) {
                    console.log('user is not authenticated - redirect to signin');
                    // Store the actually requested url so we can redirect
                    // after authentication to this page.
                    if (signInUser == null && loginCode == null) {
                        // Die Return url enthält den LoginCode, deshalb
                        // speichern wir sie nicht, wenn der Benutzer mit einem Logincode
                        // einloggt.
                        window.sessionStorage.setItem('returnUrl', window.location.href);
                    }
                    context.state.oidc.instance.signinRedirect();
                } else {
                    console.log('user authenticated');
                    context.commit('storeOidcUser', user);
                    resolve();
                }
            });
        });
    },

    // MISC
    // **************************************************

    /**
     * Loads the configured file size limits from the backend
     * end stores it in the state.
     * @param context 
     */
    async loadFileSizeLimits(context: any) {
        const response = await httpClient().get('Article/GetFileSizeLimits');
        context.commit('setFileSizeLimits', response.data);
    },


    // USER / CUSTOMER
    // **************************************************

    async checkAccess(context: any) {
        console.log('check access');
        const response = await httpClient().get(`Customer/CheckAccess`);
    },

    setCustomerInfo(context: any, customerInfo: CustomerInfoDto) {
        context.commit('setCustomerInfo', customerInfo);
        context.commit('setIsAdditionalEmailSelected',  customerInfo.defaultAdditionalOrderConfirmationEmail && customerInfo.defaultAdditionalOrderConfirmationEmail.length > 0);
        context.commit('setAdditionalOrderConfirmationEmail', customerInfo.defaultAdditionalOrderConfirmationEmail);
    },

    logout(context: any) {
        console.log('logout user. Redirecting user to signout');
        context.state.oidc.instance.signoutRedirect();
    },

    /**
     * Loads all users of the current customer from the backend and transforms
     * it to suit the article filter for users.
     * @param context 
     */
    async loadUsers(context: any) {
        const response = await httpClient().get('Customer/GetUsersOfCustomer');
        let loadedUsers = response.data.map((user: any) => {
            return { userId: user.userId, name: `${user.firstname} ${user.lastname}`};
        });
        loadedUsers.splice(0, 0, { userId: '-1', name: 'Allen Benutzern'});
        context.commit('setUsers', loadedUsers);
    },

    // ARTICLES
    // **************************************************

    /**
     * Loads all articles that are not in the state yet.
     * @param context 
     * @param articleIds Ids of articles to load.
     * @returns 
     */
    async loadMissingArticles(context: any, articleIds: number[]){
        // Find missing articles
        articleIds = articleIds.filter((a) => !context.state.articles.some((sa: CustomerArticleDto) => sa.articleId == a));
        if (articleIds.length <= 0) {
            return;
        }

        try {
            context.state.loading = true;
            let response = await httpClient().post('article/GetArticlesById', articleIds);
            response.data.forEach((article: any) => {
                article.calculatingMessage = '';
            });
            context.commit('addOrUpdateArticles', response.data);
        } catch(err) {
            console.error(`Failed to load missing articles. Error: ${err}`);
        } finally {
            context.state.loading = false;
        }
    },
    async updateArticleById(context: any, articleId: number){
        try {
            let response = await httpClient().post('article/GetArticlesById', [articleId]);
            response.data.forEach((article: any) => {
                article.calculatingMessage = '';
            });
            context.commit('addOrUpdateArticles', response.data);
        } catch(err) {
            console.log(`Failed to update article ${articleId}. Error: ${err}`);
        }
    },
    async deleteArticle(context: any, data: any) {
        context.commit('deleteArticle', data.articleId);
        if (data.refreshCart) {
            const response = await httpClient().get('cart/GetCartsOfCustomer');
            context.commit('setSavedCarts', response.data);
        }
    },

    getArticleById(context: any, articleId: number) {
        return context.state.articles.filter((a: CustomerArticleDto) => a.articleId === articleId)[0];
    },

    async blexonisieren(context: any, payload: any) {
        payload.article.calculating = payload.validate;
        await httpClient().post(
            'Article/AssignWerkstoffToArticle?' +
            `articleId=${payload.article.articleId}` +
            `&werkstoffId=${payload.article.werkstoffId}` +
            `&thickness=${payload.article.thickness}` +
            `&validate=${payload.validate}`
        );
        context.commit('moveImportingToMain', payload.article);
    },

    replaceArticle(context:any, articleConstruct: any) {
        const oldArticleId: number = articleConstruct.oldArticleId;
        const cartIndex = context.state.currentCart.cartItems.findIndex((c:CartItem) => c.articleId == oldArticleId);
        // Entferne Artikel aus dem persistierten Warenkorb, wenn vorhanden
        if (cartIndex >= 0) {
            context.dispatch('updateCurrentCartOnServer', [ { articleId: articleConstruct.oldArticleId, quantity: 0 } ]);
        }
        const oldStateArticleIndex = context.state.articles.findIndex((a:CustomerArticleDto) => a.articleId == oldArticleId);
        if (oldStateArticleIndex >= 0 && context.state.articles[oldStateArticleIndex].isAssembly) {
            console.log("Replaced article is an assembly. Completely deleting the assembly first");
            context.commit("deleteArticle", oldArticleId);
            context.commit("replaceArticle", articleConstruct);
            context.commit("addCatalogueArticleOnTop", articleConstruct.newArticle.articleId)
        } else {
            context.commit("replaceArticle", articleConstruct);
        }
    },

    // ORDER / OFFER
    // **************************************************

    resetOrderAfterSuccess(context: any){
        console.log('resetting after successful creation of order');
        context.commit('setCurrentOfferToNull');
        context.dispatch('emptyCart');
    },

    setOfferDeliveryChoice(context: any, newType: string){
        context.commit('setOfferDeliveryChoice', newType);
        if (newType === "delivery" && context.state.offer.defaultManufacturerId) {
            context.commit('changeSelectedManufacturer', context.state.offer.defaultManufacturerId);
        }
    },

    async reloadOrders(context: any) {
        const response = await httpClient().get(`order/getOrdersOfCustomer?` +
            `from=0` +
            `&take=50`);
        context.commit('setOrders', {
            reload: true,
            orders: response.data
        });
    },

    async reloadCancellationRequests(context: any) {
        const response = await httpClient().post('order/GetOrderCancellationRequestsForOrders', 
        context.state.orders.map((o: any) => o.orderId));
        context.commit("setOrderCancellations", response.data);
    },

    // CART
    // **************************************************

    async addToCart(context: any, newCartItem: any) {
        context.commit('addToCart', newCartItem);
        await context.dispatch('updateCurrentCartOnServer', [ newCartItem ]);
    },

    async removeArticleFromCart(context: any, articleId: number){
        context.commit("removeArticleFromCart", articleId);
        await context.dispatch('updateCurrentCartOnServer', [ { articleId: articleId, quantity: 0 } ]);
    },

    async setAmountOfCartItem(context: any, payload: any){
        context.commit("setAmountOfCartItem", payload);
        await context.dispatch('updateCurrentCartOnServer', [ { articleId: payload.articleId, quantity: payload.quantity } ]);
    },

    async initializeCart(context: any, userAddresses: any) {
        context.commit("initializeCart", userAddresses);
        try {
            const response = await httpClient().get('Cart/GetCurrentCart');
            let currentCart: any = response.data;
            context.dispatch("setCurrentCart", currentCart);
        } catch {
            console.log('Failed to load current cart');
        }
    },

    async setCurrentCart(context:any, currentCartFromServer: any) {
        // Load missing articles
        await context.dispatch('loadMissingArticles', currentCartFromServer.positions.map((p: CartItem) => p.articleId));
        // Remove articles that are not in the cart on the server
        const positionsToRemove = context.state.currentCart.cartItems.filter((local:any) =>
            !currentCartFromServer.positions.some((server:any) => server.articleId === local.articleId)
        );
        positionsToRemove.forEach((p:any) => context.commit("removeArticleFromCart", p.articleId));
        // Add or update items with data from the server if it's different that the local values
        currentCartFromServer.positions.forEach((serverPos: CartItem) => {
            const localPos = context.state.currentCart.cartItems.find((pos:any) => pos.articleId === serverPos.articleId)
            if (localPos) {
                if (localPos.quantity != serverPos.quantity) {
                    context.commit("setAmountOfCartItem", { articleId: serverPos.articleId, quantity: serverPos.quantity });
                }
            } else {
                let newCartItem = new CartItem();
                newCartItem.articleId = serverPos.articleId;
                newCartItem.quantity = serverPos.quantity;
                context.commit('addToCart', newCartItem);
            }
        })        
    },

    loadSavedCartIntoCurrentCart(context: any, savedCartpositions: SavedCartPosition[]){
        savedCartpositions.forEach((item) => {
            context.dispatch('addArticleFromSavedCartToCurrentCart', { articleId: item.articleId, quantity: item.quantity });            
        });
    },

    addArticleFromSavedCartToCurrentCart(context: any, articleData: any) {
        let cartItem = (context.state.currentCart as Cart).cartItems.find((i) => i.articleId === articleData.articleId);
        if (cartItem != undefined) {
            cartItem.quantity = cartItem.quantity + parseInt(articleData.quantity);
        } else {
            cartItem = {
                articleId: articleData.articleId,
                quantity: parseInt(articleData.quantity),
            };
            context.commit('addToCart', cartItem);
        }
        context.dispatch('updateCurrentCartOnServer', [ cartItem ]);
    },

    async emptyCart(context:any){
        context.commit("emptyCart");
        context.dispatch("emptyCurrentCartOnServer");
    },

    async updateCurrentCartOnServer(context: any, updatedCartItems: any) {
        try {
            await httpClient().post("Cart/UpdateCurrentCartPositions", updatedCartItems);
        } catch {
            console.error('Failed to update persistant current car');
        }
    },

    async emptyCurrentCartOnServer(context: any) {
        try {
            await httpClient().post('Cart/EmptyCurrentCart');
        } catch {
            console.error('Failed to empty persisted current cart');
        }
    },


    // SAVED CART
    // **************************************************

    async saveNewSavedCart(context: any, cart: SavedCartDto) {
        try {
            const response = await httpClient().post('Cart/SaveCart', cart);
            let newCartId: number = response.data;
            cart.id = newCartId;
            context.commit('saveNewSavedCart', cart);
        } catch {
            console.log('Failed to save cart with id' + cart.id);
        }
    },
    async deleteSavedCart(context: any, cartId: number){
        try {
            await httpClient().post(`Cart/DeleteCart?cartId=${cartId}`);
            context.commit('deleteSavedCart', cartId);
        } catch {
            console.log('Failed to delete saved cart with id ' + cartId);
        }
    },
    async setAmountOfSavedCartItem(context: any, data: any) {
        try {
            await httpClient().post(`Cart/SetCartArticleQuantity?cartId=${data.cartId}&articleId=${data.articleId}&quantity=${data.quantity}`);
            context.commit('setAmountOfSavedCartItem', data);
            const output = `Die Anzahl für Artikel ${data.articleId} wurde in der Stückliste auf ${data.quantity} Stk. festgelegt.`;
            context.dispatch('setSnackbarText', output);
        } catch(err) {
            console.error(`Failed to set amount of article in saved cart. Error: ${err}`);
            context.dispatch('setSnackbarErrorText', 'Stückzahl konnte nicht angepasst werden.');
        }
    },
    async removeArticleFromSavedCart(context: any, data: any) {
        try {
            await httpClient().post(`Cart/RemoveArticleFromSavedCart?cartId=${data.cartId}&articleId=${data.articleId}`);
            context.commit('removeArticleFromSavedCart', data);
        } catch(err) {
            console.error(`Failed to remove article from saved cart. Error: ${err}`);
            context.dispatch('setSnackbarErrorText', 'Artikel konnte nicht aus Stückliste entfernt werden.');
        }
    },
    async addArticleToSavedCart(context: any, data: any) {
        try {
            const articleId = data.articleId;
            const amount = data.amount;
            const customerArticleName = data.customerArticleName;
            const index = context.state.savedCarts.findIndex((cart:SavedCartDto) => cart.id == context.state.currentSavedCartId);
            if (index >= 0) {
                const cart = context.state.savedCarts[index];
                const articleIndex = cart.positions
                    .findIndex((item: SavedCartPosition) => item.articleId === articleId);
                if (articleIndex >= 0) {
                    // Menge anpassen
                    await context.dispatch('setAmountOfSavedCartItem', {
                        cartId: context.state.currentSavedCartId,
                        articleId: articleId,
                        quantity: amount,
                    });
                } else {
                    context.commit('addArticleToSavedCart', {
                        cartId: context.state.currentSavedCartId,
                        articleId: articleId,
                        quantity: amount,
                        customerArticleName: customerArticleName,
                    });
                    await httpClient().post('Cart/SaveCart', cart);
                }
            }
            else {
                console.error(`Cart with id ${context.state.currentSavedCartId} kann nicht gefunden werden.`);
            }
        } catch(err) {
            console.error(`Failed to add article from cart to saved cart. Error: ${err}`);
            context.dispatch('setSnackbarErrorText', 'Artikel konnte nicht der Stückliste hinzugefügt werden.');
        }
    },

    // ADDRESSES
    // **************************************************

    async deleteAddress(context: any, addressId: number) {
        try {
            await httpClient().post(`Customer/DeleteAddress?addressId=${addressId}`, null);
            context.dispatch('setSnackbarText', 'Adresse wurde gelöscht.');
            // Reload customer data for new addresses and refresh
            const response = await httpClient().get("Customer/GetCustomer");
			context.commit("setCustomerInfo", response.data);
            // Adjust order addresses that could still contain the deleted address
            
        } catch(err) {
            console.error(`Failed to delete address. Error: ${err}`);
            context.dispatch('setSnackbarErrorText', 'Adresse konnte nicht gelöscht werden.');
        }
    },
    async setAddressAsUserPreferred(context: any, addressData: any) {
        try {
            await httpClient().post(`Customer/SetAddressAsUserPreferred?addressId=${addressData.id}`, null);
            context.dispatch('setSnackbarText', 'Adresse wurde als Favorit markiert.');
            context.commit('setAddressAsUserPreferred', addressData);
        } catch(err) {
            console.error(`Failed to set address as user preferred address. Error: ${err}`);
            context.dispatch('setSnackbarErrorText', 'Adresse konnte nicht als Favorit markiert werden.');
        }
    },

    async loadManufacturerAdresses(context: any) {
        try {
            const response = await httpClient()
                .get('manufacturer/GetManufacturerAddresses?regionId=' + context.state.customer.regionId);
            context.commit('setManufacturerAdresses', response.data);
        } catch(err) {
            console.error(`Failed to load manufacturer adresses. Error: ${err}`);
        }
    },
    
    // SNACKBAR
    // **************************************************
     
    setSnackbarText(context: any, text: any) {
        context.commit('setSnackbarText', text);
        context.dispatch('showSnackbar');
    },
    setSnackbarErrorText(context: any, text: any) {
        context.commit('setSnackbarErrorText', text);
        context.dispatch('showSnackbar');
    },
    setSnackbarArticleText(context: any, data: any) {
        context.commit('setSnackbarArticleText', data);
        context.dispatch('showSnackbar');
    },
    showSnackbar(context: any) {
        /*
        Das Problem mit der Snackbar...
        Früher wurde die Snackbar getoggled über den Text. Wenn der Text leer war, wurde sie nicht angezeigt.
        Das geht nicht mehr, da inzwischen nicht nur Text in der Snackbar angezeigt wird.
        Die Snackbar hat ein timeout Attribut. Wird das v-model der Snackbar auf "true" gesetzt, stellt sie sich
        automatisch wieder auf "false" nach Ablauf des timeouts.
        Das ist ein Problem, wenn eine neue Snackbar angezeigt werden soll, wenn noch eine aktiv ist,
        denn das timeout wird nicht resettet, wenn man einfach das v-model auf "false" und dann wieder "true" setzt.
        Der einzige (mir bekannte) Weg, ist das timeout attribut zu verändern. (Steht auch so in der Vuetify Doku)
        Das resettet das timeout.
        Deshalb die folgende Umsetzung:
        - Wenn bereits eine Snackbar angezeigt wird (showSnackbar = true)
            - Blende die Snackbar kurz aus (visuelle Unterstützung -> jetzt kommt eine neue Snackbar!)
            - Nach 250ms blende die neue Snackbar ein (Content wurde vorher bereits entsprechend angepasst)
            - Modifiziere das timeout attribut um +- 1ms, sodass das timeout resettet wird
        - Wenn keine Snackbar angezeigt wird, zeige die Snackbar an.
        */
        if (context.state.showSnackbar) {
            context.commit('setShowSnackbar', false);
            setTimeout(() => {
                context.commit('adjustSnackbarTimeout');
                context.commit('setShowSnackbar', true);
            }, 250);
        } else {
            context.commit('setShowSnackbar', true);
        }
    },
    /**
     * Deletes pending orders which are in the current shopping cart
     * @param context 
     */
    async deletePendingOrdersInCart(context: any) {
        for (const order of context.state.pendingOrders) {
            if (order.isInCart) {
                try {
                    await httpClient().delete(`order/DeletePendingOrder?cartId=${order.id}`);
                    context.commit('removePendingOrder', order.id)
                } catch(e) {
                    console.error(e);
                }
            }
        }
    },

    async startArticleCalculatingPollingTimer(context: any) {
        const intervalId = setInterval(() => {
            let d = new Date();
            let calculatingArticleIds = context.getters.calculatingArticleIds;
            if (calculatingArticleIds.length > 0) {
                context.dispatch('pollArticleData', calculatingArticleIds );
            }
        }, 60_000);
        context.commit('setArticleCalculatingPollingTimerId', intervalId);
    },
    
    async pollArticleData(context: any, articleIds: any) {
        console.log(`Polling article data of calculating articles: ${articleIds.join(", ")}`);
        try {
            articleIds.forEach((id: any) => context.dispatch('updateArticleById', id));            
        } catch(e) {
            console.error('Failed to poll data of calculating articles');
        }
    },


    // SIGNAL R
    // **************************************************
    setupSignalR(context: any) {
        //@ts-ignore
        const connection = new signalR.HubConnectionBuilder()
        .withUrl(process.env.VUE_APP_eventUrl + 'signalhub', { accessTokenFactory: () => {
                const userString = window.localStorage
                    .getItem(`oidc.user:${process.env.VUE_APP_authorityUrl}:BlexonKundenportalClient`);
                //@ts-ignore
                const user = JSON.parse(userString);
                return user.access_token;
            }
        })
        .configureLogging(signalR.LogLevel.Information)
        .build();

        async function start() {
            try {
                await connection.start();
                console.log("Signal connected");
            } catch (err) {
                console.log(err);
                setTimeout(() => start(), 5000);
            }
        };

        connection.onclose(async () => {
            console.log("signalR closed");
            await start();
        });

        connection.on("ArticleUpdated", (message: any) => {
            var article = JSON.parse(message.jsonObject);
            console.log(`Article ${article.articleId} was updated`);
            context.commit('updateArticle', article);
        });

        connection.on("ArticleReplaced", async (message: any) => {
            var articleConstruct = JSON.parse(message.jsonObject);
            console.log("Article replace message received: ",
                { oldArticleId: articleConstruct.oldArticleId, newArticleId: articleConstruct.newArticle.articleId });
            context.dispatch('replaceArticle', articleConstruct);
            // Reload saved carts because articles might have been replaced in them
            const response = await httpClient().get('cart/GetCartsOfCustomer');
            console.log('Reloaded saved carts.');
            context.commit('setSavedCarts', response.data);
        });

        connection.on("ArticleUpdatedAdministrators", (message: any) => {
            // This method is intentionally empty
            // SignalR sends updates concerning ALL articles to Administrators
            // If this message is not caught by this method, it results in a warning
            // in the console, which clutters the console.log up.
            // Users don't see this warning but we still decided to let the
            // message run into the void.
        });

        connection.on("ArticleReplacedAdministrators", (message: any) => {
            // This method is intentionally empty
            // SignalR sends updates concerning ALL articles to Administrators
            // If this message is not caught by this method, it results in a warning
            // in the console, which clutters the console.log up.
            // Users don't see this warning but we still decided to let the
            // message run into the void.
        });

        connection.on("MessageForArticle", (articleId: number, message: string) => {
            context.commit('setCalculatingMessage', {
                articleId: articleId,
                message: message
            });
        });

        connection.on("KundenportalError", (message: any) => {
            console.log('SignalR Event KundenportalError caught');
            context.commit('setBannerErrorMessage', message);
        });

        connection.on("KundenportalInfo", (message: any) => {
            console.log('SignalR Event KundenportalInfo caught');
            context.commit('setBannerInfoMessage', message);
        });

        connection.on("SignOut", async (message: any) => {
            console.log('SignOut message received...');
            context.dispatch('logout');
        });

        connection.on("CurrentCart", async (message: any) => {
            context.dispatch('setCurrentCart', JSON.parse(message.jsonObject));
        });

        // Start the connection.
        start();   
    }
};
