
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import ArticleTile from './ArticleTile.vue';
import MultiEdit from './Multiedit.vue';
import { CustomerArticleDto } from '@/dtos/customerArticleDto';
@Component({
    components: {
        ArticleTile,
        MultiEdit,
    }
})
export default class Assembly extends Vue {
    @Prop()
    private assemblyId!: number;

    private async mounted() {
        this.$store.commit('clearSelectedArticleIds');
        this.$store.dispatch('loadMissingArticles', [this.assemblyId])
            .then(() => 
                this.$store.dispatch('loadMissingArticles', this.assembly.subArticleIds)
            );
    }

    /**
     * Gets the article that represents the whole assembly
     */
    private get assembly() : CustomerArticleDto {
        return this.$store.state.articles.find((a:CustomerArticleDto) => a.articleId == this.assemblyId)
    }

    private get subarticles() : CustomerArticleDto[] {
        return this.$store.state.articles.filter((a:CustomerArticleDto) => this.assembly.subArticleIds.includes(a.articleId))
    }
}
