
import { Component, Prop, Vue } from "vue-property-decorator";
import { CartItem } from "@/dtos/cartItem";
import CartPositionItem from "./CartPositionItem.vue";

@Component({
	components: {
		CartPositionItem,
	},
})
export default class CartPosition extends Vue {
	@Prop()
	private position!: CartItem;
	private isExpanded: boolean = false;
	private hasLoadedSubArticles: boolean = false;
	private isLoadingSubarticles: boolean = false;

	private get article() {
		return this.$store.getters.articleById(this.position.articleId);
	}

	private get assemblySubPositions(): CartItem[] {
		if (!this.article.isAssembly || !this.article.subArticleIds) return [];
		// For quantity we give the quantity of the parent (Baugruppe).
		// With this, the quantity of the child can be calculated.
		const subArticles = this.article.subArticleIds.map((sa: number) => ({
			articleId: sa,
			quantity: this.position.quantity,
		}));
		return subArticles;
	}

	private async toggleExpand() {
		// If this is expanded the first time, the subarticles may need to be loaded into the state
		if (!this.isExpanded && !this.hasLoadedSubArticles) {
			try {
				this.isLoadingSubarticles = true;
				this.$store.dispatch("loadMissingArticles", this.article.subArticleIds);
				this.hasLoadedSubArticles = true;
			} catch {
				console.log("Failed to load missing subarticles.");
			} finally {
				this.isLoadingSubarticles = false;
			}
		}

		this.isExpanded = !this.isExpanded;
	}
}
