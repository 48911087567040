var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      !_vm.isLoading && !_vm.order.orderId
        ? _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [_vm._v(" Der Auftrag konnte nicht gefunden werden. ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.order.orderId > 0
        ? _c(
            "v-container",
            [
              _c("h2", [
                _vm._v(
                  "Stornierung für Auftrag #" +
                    _vm._s(_vm.order.orderId) +
                    " beantragen"
                ),
              ]),
              _vm.orderProcessedInMeantime
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "red--text", attrs: { cols: "12" } },
                        [
                          _c("p", [
                            _vm._v(
                              " Leider hat die Verarbeitung des Auftrags inzwischen begonnen. "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        " Dieser Auftrag wird bereits bearbeitet. Eine Stornierung kann aber trotzdem beantragt werden."
                      ),
                      _c("br"),
                      _vm._v(
                        " Wir klären die bereits entstandenen Kosten ab und kontaktieren Sie per E-Mail. Sie entscheiden dann, welche Positionen des Auftrags Sie definitiv stornieren möchten. "
                      ),
                      _c("br"),
                      _vm._v(
                        " Selbst wenn nach der Kostenabklärung auf die Stornierung verzichtet wird, ist eine Bearbeitungsgebühr von "
                      ),
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.order.currency) +
                            " " +
                            _vm._s(_vm.fixCost > 0 ? _vm.fixCost : "...") +
                            ".-"
                        ),
                      ]),
                      _vm._v(
                        " fällig, sobald Sie eine Stornierung beantragen."
                      ),
                      _c("br"),
                      _c("br"),
                      _vm._v(
                        " Bitte selektieren Sie die zu stornierenden Artikel: "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c("v-simple-table", {
                staticStyle: { width: "100%" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c("thead", [
                          _c("tr", [
                            _c(
                              "th",
                              [
                                _c("v-checkbox", {
                                  attrs: {
                                    color: "accent",
                                    "hide-details": "",
                                  },
                                  on: { change: _vm.toggleAllPositions },
                                  model: {
                                    value: _vm.toggleAll,
                                    callback: function ($$v) {
                                      _vm.toggleAll = $$v
                                    },
                                    expression: "toggleAll",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("th", [_vm._v("Position")]),
                            _c("th", [_vm._v("Menge")]),
                            _c("th", [_vm._v("Vorschau")]),
                            _c("th", [_vm._v("Beschreibung")]),
                            _c("th", { staticClass: "text-right" }, [
                              _vm._v("Preis / Stk"),
                            ]),
                            _c("th", { staticClass: "text-right" }, [
                              _vm._v("Preis Total"),
                            ]),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          _vm._l(_vm.positions, function (pos) {
                            return _c("tr", { key: pos.position }, [
                              _c(
                                "td",
                                [
                                  _c("v-checkbox", {
                                    attrs: {
                                      color: "accent",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: pos.isSelected,
                                      callback: function ($$v) {
                                        _vm.$set(pos, "isSelected", $$v)
                                      },
                                      expression: "pos.isSelected",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("td", [
                                _vm._v(" " + _vm._s(pos.position) + " "),
                              ]),
                              _c("td", [
                                _vm._v(" " + _vm._s(pos.quantity) + " "),
                              ]),
                              _c(
                                "td",
                                { staticClass: "pa-1" },
                                [
                                  _c("v-img", {
                                    attrs: {
                                      contain: "",
                                      width: "90px",
                                      height: "90px",
                                      src: _vm.getThumbnailUrl(
                                        pos.blexonArticleId
                                      ),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("td", [
                                _vm._v(
                                  " Blexon Id: " + _vm._s(pos.blexonArticleId)
                                ),
                                _c("br"),
                                _c("b", [
                                  _vm._v(_vm._s(pos.customerArticleName)),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" " + _vm._s(pos.materialname) + " "),
                              ]),
                              _c(
                                "td",
                                { staticStyle: { "text-align": "right" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("round2Decimal")(
                                          _vm.getPrice(pos)
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "td",
                                { staticStyle: { "text-align": "right" } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("round2Decimal")(
                                          _vm.getPrice(pos) * pos.quantity
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ])
                          }),
                          0
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("v-divider"),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-checkbox", {
                        attrs: {
                          color: "accent",
                          "hide-details": "",
                          label:
                            "Ich akzeptiere die pauschale Bearbeitungsgebühr von " +
                            _vm.order.currency +
                            " " +
                            _vm.fixCost +
                            ".-, welche bei der Beantragung der Stornierung anfällt.",
                        },
                        model: {
                          value: _vm.acceptingCosts,
                          callback: function ($$v) {
                            _vm.acceptingCosts = $$v
                          },
                          expression: "acceptingCosts",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-checkbox", {
                        attrs: {
                          color: "red",
                          "hide-details": "",
                          label:
                            "OPTIONAL! Ich möchte die ausgewählten Positionen, unabhängig von den entstandenen Kosten, definitiv stornieren. " +
                            "Ich bestätige, alle Stornierungskosten für die Positionen und die Stornierungspauschale zu übernehmen " +
                            "und auf die Teile zu verzichten.",
                        },
                        model: {
                          value: _vm.forceCancellation,
                          callback: function ($$v) {
                            _vm.forceCancellation = $$v
                          },
                          expression: "forceCancellation",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "pa-0 mx-0 my-3" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { staticClass: "mr-3", on: { click: _vm.goToOrders } },
                    [_vm._v(" Abbrechen ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "black--text",
                      attrs: {
                        color: _vm.forceCancellation ? "red" : "accent",
                        disabled: !_vm.cancellationRequestIsValid,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.requestOrderCancellation()
                        },
                      },
                    },
                    [
                      _vm.isLoading
                        ? _c("v-progress-circular", {
                            attrs: { size: "15", indeterminate: "" },
                          })
                        : _vm._e(),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.forceCancellation
                              ? "Definitiv stornieren"
                              : "Stornierung beantragen"
                          ) +
                          " "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }