
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import httpClient from '@/store/httpClient';
import rules from '@/store/rules'
import { CustomerArticleDto } from '@/dtos/customerArticleDto';
import { WerkstoffDto } from '@/dtos/werkstoffDto';
import { ArticleDataDto } from '@/dtos/articleDataDto';
import { MaterialDto } from '@/dtos/materialDto';
import { Entgratart } from '@/dtos/entgratart';
import { EntgratartOptionDto } from '@/dtos/entgratartOptionDto';

@Component({
	components: {
	}
})
export default class ArticleEditDialog extends Vue {
	@Prop()
	private articleEditDialog!: boolean;
	@Prop()
	private article!: CustomerArticleDto;
	private isUpdatingArticleData: boolean = false;
	private articleDataValid: boolean = true;
	private articleName: string = '';
    private articleNumber: string = '';
    private selectedWerkstoffId: number = 0;
    private entgratart: Entgratart = Entgratart.None;
	private loadingEntgratarten: boolean = false;
	private availableEntgratarten: EntgratartOptionDto[] = new Array<EntgratartOptionDto>()
    private saveFailedErrorMessage: String = '';
	private requiredStringMax50Rule: any = rules.requiredStringMax50Rule;
	private optionalStringMax50Rule: any = rules.optionalStringMax50Rule;

	@Watch('articleEditDialog')
    private resetEditArticleData() {
        if (this.articleEditDialog) {
            this.articleName = this.article.customerArticleName;
            this.articleNumber = this.article.customerArticleId;
            this.selectedWerkstoffId = this.article.werkstoffId;
            this.entgratart = this.article.entgratart;
			this.loadEntgratarten();
        }
    }

	@Watch('selectedWerkstoffId')
	private onSelectedWerkstoffIdChanged() {
		if (this.hasWerkstoffChanged) {
			console.log('resetting entgratart');
			this.entgratart = this.article.entgratart;
		}
	}

	private async loadEntgratarten() {
		this.loadingEntgratarten = true;
		const response = await httpClient().get(`article/GetEntgratarten?articleId=${this.article.articleId}`);
		this.availableEntgratarten = response.data;
		this.loadingEntgratarten = false;
	}

	private isEntgratartDisabled = (item: EntgratartOptionDto) => {
		return !item.available;
	}

	// Werkstoffe, die für den Artikel in Frage kommen.
    // Konnte die Dicke nicht berechnet werden --> Alle Werkstoffe anzeigen.
    // Konnte die Dicke berechnet werden --> Werkstoffe anzeigen, die in der Dicke verfügbar sind.
    private get availableWerkstoffe() {
        if (this.article.thickness <= 0) {
            return (this.$store.state.werkstoffe as WerkstoffDto[]);
        }
        return (this.$store.state.werkstoffe as WerkstoffDto[])
            .filter((w: WerkstoffDto) => w.materials.some((m:MaterialDto) => m.thickness === this.article.thickness));
    }

	private get hasWerkstoffChanged() {
		return this.selectedWerkstoffId !== this.article.werkstoffId;
	}

	private async saveArticleData() {
        const sanitisedName = this.articleName.trim();
        const sanitisedNumber = this.articleNumber.trim();
        try {
            if(this.articleDataValid 
                && (this.article.customerArticleName !== sanitisedName 
                || this.article.customerArticleId !== sanitisedNumber)) 
            {
                this.setUpdating(true);
                let articleData: ArticleDataDto = {
                    articleId: this.article.articleId,
                    customerArticleId: sanitisedNumber,
                    customerArticleName: sanitisedName,
                };

                await httpClient().post('article/UpdateArticleData', articleData);
                this.$store.commit('saveArticleData', articleData);
            }

			if (this.hasWerkstoffChanged) {
				this.setUpdating(true);
				await httpClient().post(
					'Article/ReplaceMaterialOfArticle?' +
					`articleId=${this.article.articleId}` +
					`&newWerkstoffId=${this.selectedWerkstoffId}`
				);
			} 
			// Only update Entgratart if material wasn't changed
			else if (this.article.entgratart !== this.entgratart) 
			{
                this.setUpdating(true);
                await httpClient().post(`article/SetEntgratart?articleId=${this.article.articleId}&entgratart=${this.entgratart}`)
                    .then(() => {this.$store.dispatch('updateArticleById', this.article.articleId)});
            }
            
            this.saveFailedErrorMessage = '';            
            this.closeDialog();
        } catch(err) {
            console.log(`Failed to save article data of article ${this.article.articleId}. Error: ${err}`);
            this.saveFailedErrorMessage = `Die Daten konnten nicht gespeichert werden. ${err}`;
        } finally {
            this.setUpdating(false);
        }
    }

	private setUpdating(value: boolean) {
		this.isUpdatingArticleData = value;
	}

	private closeDialog() {
		this.$emit('closeDialog');
	}
}

