import { AddressDto } from '@/dtos/addressDto';
import { PriceListDto } from './../dtos/priceListDto';
import { PowderDto } from './../dtos/powderDto';
import { PriceDto } from "./../dtos/priceDto";
import { CartItem } from "@/dtos/cartItem";
import { CustomerArticleDto } from "@/dtos/customerArticleDto";
import { ManufacturingOfferDto } from "@/dtos/manufacturingOfferDto";
import moment from "moment";
import { SurfaceColorDto } from '@/dtos/surfaceColorDto';
import { PriceModel } from '@/dtos/priceModel';
import PriceModelDefault from '@/dtos/priceModel';
import { OrderDiscountDto } from '@/dtos/orderDiscountDto';
import { MwstDto } from '@/dtos/mwstDto';
import { WerkstoffDto } from '@/dtos/werkstoffDto';
import { MaterialDto } from '@/dtos/materialDto';
import { AssemblyInfoDto } from '@/dtos/assemblyInfoDto';
import { SubarticleInfoDto } from '@/dtos/subarticleInfoDto';

export default {
	customerId(state: any) {
		if (state.customer != null) {
			return state.customer.customerId;
		}
		return 0;
	},
	articleById: (state: any) => (articleId: number) => {
		return state.articles.find((a: CustomerArticleDto) => a.articleId === articleId);
	},
	
	quantityOfItemInCart: (state: any) => (articleId: number) => {
		var currentCartItem: CartItem = state.currentCart.cartItems.find((item: CartItem) => item.articleId == articleId);        
		return currentCartItem != undefined ? currentCartItem.quantity : 0;
	},
	totalOfAllItemsInCart(state: any, getters: any) {
		if (state.offer) {
			return state.offer.totalOfAllArticlesInCart
		}
		return 0.0;
	},
	isCartValid(state: any) {
		if (!state.currentCart || state.currentCart.cartItems == null || state.currentCart.cartItems.length == 0) {
			return false;
		}
		for (var i = 0; i < state.currentCart.cartItems.length; i++) {
			let articlesToCheck: CustomerArticleDto[] = [];
			let currentArticle: CustomerArticleDto = state.articles.find(
				(article: CustomerArticleDto) => article.articleId == state.currentCart.cartItems[i].articleId
			);
			articlesToCheck.push(currentArticle);
			if (currentArticle.subArticleIds && currentArticle.subArticleIds.length > 0) {
				// Add subarticles to the list of articles to check
				currentArticle.subArticleIds.forEach((sa: number) => {
					let subArticle = state.articles.find(
						(article: CustomerArticleDto) => article.articleId == sa
					);
					articlesToCheck.push(subArticle);
				});
			}
			articlesToCheck.forEach((a: CustomerArticleDto) => {
				if (a == null 
					|| a.calculating
					|| a.migrated 
					|| (a.state != 0 && a.state != 1)
					|| a.priceState !== 2)
				{
					return false;
				}
			})
		}
		return true;
	},
	selectedManufacturer(state: any) {
		return state.offer.manufacturingOffers.find(
			(manufacturer: ManufacturingOfferDto) => manufacturer.manufacturerId == state.offer.selectedManufacturerId
		);
	},
	amountOfItemsInCart(state: any) {
		return state.currentCart.cartItems.length;
	},
	waehrung(state: any) {
		return state.customer.currency;
	},
	invoiceCharge(state: any) {
		if (state.offer === null) return 0.0;
		return state.currentCart.invoiceAddress.isBilledDigitally ? 0.0 : 2.0;
	},
	mwstRatio: (state: any) => (date: Date) => {
		return state.customer.mwstRates
			.filter((r: MwstDto) => {
				let rateDate = new Date(r.validFrom);
				let givenDate = new Date(date);
				return rateDate.setHours(0,0,0,0) <= givenDate.setHours(0,0,0,0);
			})
			.sort((a: MwstDto, b: MwstDto) => {
				let aDate = new Date(a.validFrom);
				let bDate = new Date(b.validFrom);
				return bDate.getTime() - aDate.getTime();
			})
			[0].rate;
	},
	deliveryCost(state: any, getters: any) {
		if (state.offer === null) return 0.0;
		if (state.offer.deliveryChoice === "delivery") {
			return getters.selectedManufacturer.deliveryCost;
		}
		return 0.0;
	},
	orderDiscount: (state: any) => (priceModel: PriceModel) => {
		if (state.offer !== null && state.offer !== undefined) {
			var orderDiscount = state.offer.orderDiscounts.filter((d:OrderDiscountDto) => d.priceModel === priceModel)[0]
			return orderDiscount;
		}
		return null;
	},
	costMwst: (state: any, getters: any) => (priceModel: PriceModel, date: Date) => {
		let mwst =
			(parseFloat(getters.totalOfAllItemsInCart) +
				parseFloat(getters.orderDiscount(priceModel).discount) +
				parseFloat(getters.deliveryCost) +
				parseFloat(getters.invoiceCharge)) *
			((getters.mwstRatio(date) as number) / 100);
		mwst = Math.round(mwst * 100) / 100;
		return mwst;
	},
	nettoOffer: (state: any, getters: any) => (priceModel: PriceModel) => {
		return (
			parseFloat(getters.totalOfAllItemsInCart) +
			parseFloat(getters.orderDiscount(priceModel).discount) +
			parseFloat(getters.deliveryCost) +
			parseFloat(getters.invoiceCharge)
		);
	},
	totalOffer: (state: any, getters: any) => (priceModel: PriceModel, date: Date) => {
		return (
			parseFloat(getters.totalOfAllItemsInCart) +
			parseFloat(getters.orderDiscount(priceModel).discount) +
			parseFloat(getters.deliveryCost) +
			parseFloat(getters.invoiceCharge) +
			parseFloat(getters.costMwst(priceModel, date))
		);
	},
	importingArticles(state: any) {
		return state.importingArticles;
	},
	temporaryImportId(state: any) {
		return state.temporaryImportId;
	},
	getThumbnailUrl: (state: any) => (articleId: number, lastChange: any) => {
		if (lastChange == undefined) {
			lastChange = moment().toJSON();
		}
		var token = state.customer.customerId * articleId * 1234;
		var tokenAsString = token.toString();

		return (
			process.env.VUE_APP_erpUrl + `article/getthumbnail?id=${articleId}&time=${lastChange}&token=${tokenAsString}`
		);
	},
	getAllSurfaceColors(state: any) {
		if (state.powders.length <= 0) return [];
		// Get colors from powders
		let colors = state.powders.filter((it: PowderDto) => it.available).map((it: PowderDto) => it.surfaceColor);
		// Remove duplicates
		colors = colors.filter((it: SurfaceColorDto, index: any, self: any) => self.findIndex((t: any) => t.id === it.id) === index);
		return colors;
	},
	articlesInCart(state: any) {
		return state.articles
			.filter((article: CustomerArticleDto) => 
				state.currentCart.cartItems.some((item: CartItem) => 
					item.articleId === article.articleId)
			)
			.sort((a: CustomerArticleDto, b: CustomerArticleDto) => {
				let aIndex = state.currentCart.cartItems.findIndex((item: CartItem) => item.articleId === a.articleId);
				let bIndex = state.currentCart.cartItems.findIndex((item: CartItem) => item.articleId === b.articleId);
				return aIndex - bIndex;
			});
	},
	availablePriceModels(state: any, getters: any) {
		if (getters.articlesInCart.length <= 0) {
			return [];
		}
		return PriceModelDefault.allPriceModels.filter((p:any) => 
			getters.articlesInCart[0].priceLists.some((pl:PriceListDto) => pl.priceModel == p.value));
	},
	/**
	 * Gets a value indicating if there is more than one price model available in this
	 * region.
	 * This only works, if there are articles loaded. 
	 * @param state current state of store
	 * @returns true, if there is at least one article with two price lists.
	 */
	hasPriceModels(state: any)
	{
		return state.articles.find((a: CustomerArticleDto) => a.priceLists && a.priceLists.length > 1) != undefined;
	},	
	customerDeliveryAddressCount(state:any) {
		if (state.customer) {
			return state.customer.deliveryAddresses.length;
		}
	},
	customerInvoiceAddressCount(state:any) {
		if (state.customer) {
			return state.customer.invoiceAddresses.length;
		}
	},
	customerDefaultDeliveryAddress(state: any) {
		if (state.customer) {
			const address = state.customer.deliveryAddresses.filter((a:any) => a.isDefault)[0];
			return address ?? new AddressDto();
		} else {
			return new AddressDto();
		}
	},
	customerDefaultInvoiceAddress(state: any) {
		if (state.customer) {
			const address = state.customer.invoiceAddresses.filter((a:any) => a.isDefault)[0];
			return address ?? new AddressDto();
		} else {
			return new AddressDto();
		}
	},
	customerIsCountryValidForDelivery(state: any) {
		if (state.customer) {
			return state.customer.isCountryValidForDelivery;
		} else {
			console.log('customer is undefined');
			return false;
		}
	},
	userPreferredDeliveryAddress(state: any) {
		if (state.customer && state.customer.user.preferredDeliveryAddressId > 0) {
			const address = state.customer.deliveryAddresses.filter((a:any) => a.id == state.customer.user.preferredDeliveryAddressId)[0];
			return address ?? new AddressDto();
		} else {
			return new AddressDto();
		}
	},
	userPreferredInvoiceAddress(state:any) {
		if (state.customer && state.customer.user.preferredInvoiceAddressId > 0) {
			const address = state.customer.invoiceAddresses.filter((a:any) => a.id == state.customer.user.preferredInvoiceAddressId)[0];
			return address ?? new AddressDto();
		} else {
			return new AddressDto();
		}
	},
	isMaintenance(state:any) {
		const isMaintenance = state.bannerErrorMessage && state.bannerErrorMessage.startsWith('MAINTENANCE ');
		return isMaintenance;
	},
	calculatingArticleIds(state: any) {
		return state.articles
			.filter((article: CustomerArticleDto) => article.calculating)
			.map((article: CustomerArticleDto) => article.articleId);
	},
	getMaterial : (state: any) => (materialId: number) => {
		return state.werkstoffe.flatMap((w:WerkstoffDto) => w.materials).find((m:MaterialDto) => m.id === materialId)
	}

};
