var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-card",
    [
      _c(
        "v-btn",
        {
          staticClass: "blexon-button",
          attrs: { fab: "", fixed: "", top: "", right: "" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_c("v-icon", [_vm._v("mdi-close")])],
        1
      ),
      _c("v-card-title", [
        _c("h2", { staticClass: "text-h5" }, [
          _vm._v("Bestätigung zu Auftrag " + _vm._s(_vm.orderId)),
        ]),
      ]),
      _c(
        "v-card-text",
        [
          _vm.loading
            ? _c("v-progress-circular", {
                attrs: { color: "accent", indeterminate: "", size: "30" },
              })
            : _vm.order
            ? _c("div", [
                _c(
                  "p",
                  [
                    _c("strong", [_vm._v("Auftragseingang:")]),
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("formatDateTime")(_vm.order.orderDate))
                    ),
                    _c("br"),
                    _vm.order.kommission == null || _vm.order.kommission == ""
                      ? _c("span", [
                          _c("strong", [_vm._v("Ihre Auftragsnummer:")]),
                          _vm._v(
                            " keine Angabe (Sie können diese im Bestellarchiv anpassen)"
                          ),
                          _c("br"),
                        ])
                      : _c("span", [
                          _c("strong", [_vm._v("Ihre Auftragsnummer:")]),
                          _vm._v(" " + _vm._s(_vm.order.kommission)),
                          _c("br"),
                        ]),
                    !_vm.order.rampenabholung
                      ? _c("span", [
                          _c("strong", [
                            _vm._v("Voraussichtlicher Liefertermin (ab Werk):"),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("formatDate")(_vm.order.dueDate)) +
                              " " +
                              _vm._s(
                                _vm.order.printPriceModelInfo
                                  ? _vm.priceModelText
                                  : ""
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " Nach Fertigstellung des Auftrags erhalten Sie eine E-Mail mit einem Link zur Sendungsverfolgung. "
                          ),
                        ])
                      : _c("span", [
                          _c("strong", [
                            _vm._v("Voraussichtliche Fertigstellung:"),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("formatDateTime")(_vm.order.dueDate)
                              ) +
                              " " +
                              _vm._s(
                                _vm.order.printPriceModelInfo
                                  ? _vm.priceModelText
                                  : ""
                              )
                          ),
                          _c("br"),
                          _c("strong", [_vm._v("Wichtig: ")]),
                          _vm._v(
                            "Sie erhalten eine E-Mail sobald Ihr Auftrag bereit zur Abholung ist. Die Abholung ist erst möglich, nachdem Sie diese E-Mail erhalten haben. "
                          ),
                        ]),
                    _c(
                      "v-row",
                      [
                        _c("v-col", [
                          _c("strong", [_vm._v("Rechnungsadresse:"), _c("br")]),
                          _vm._v(
                            " " + _vm._s(_vm.order.invoiceAddress.companyName)
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(_vm.order.invoiceAddress.givenName) +
                              " " +
                              _vm._s(_vm.order.invoiceAddress.surname)
                          ),
                          _c("br"),
                          _vm._v(" " + _vm._s(_vm.order.invoiceAddress.street)),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(_vm.order.invoiceAddress.zip) +
                              " " +
                              _vm._s(_vm.order.invoiceAddress.town) +
                              " (" +
                              _vm._s(_vm.order.invoiceAddress.country) +
                              ")"
                          ),
                          _c("br"),
                        ]),
                        _c("v-col", [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.order.rampenabholung
                                  ? "Abholadresse"
                                  : "Lieferadresse"
                              ) + ":"
                            ),
                            _c("br"),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.order.rampenabholung
                                  ? _vm.fertiger.companyName
                                  : _vm.order.deliveryAddress.companyName
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.order.rampenabholung
                                  ? _vm.fertiger.name
                                  : `${_vm.order.deliveryAddress.givenName} ${_vm.order.deliveryAddress.surname}`
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.order.rampenabholung
                                  ? _vm.fertiger.steet
                                  : _vm.order.deliveryAddress.street
                              )
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.order.rampenabholung
                                  ? _vm.fertiger.zipAndTown
                                  : `${_vm.order.deliveryAddress.zip} ${_vm.order.deliveryAddress.town} (${_vm.order.deliveryAddress.country})`
                              )
                          ),
                          _c("br"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticStyle: { overflow: "auto" } }, [
                  _c(
                    "table",
                    { staticClass: "positionsTable" },
                    [
                      _c("tr", [
                        _c("th", [_vm._v("Position")]),
                        _c("th", [_vm._v("Menge")]),
                        _c("th", [_vm._v("Vorschau")]),
                        _c("th", [_vm._v("Beschreibung")]),
                        _c("th", [_vm._v("Preis/Stk")]),
                        _c("th", [
                          _vm._v(
                            "Gesamtpreis (" + _vm._s(_vm.order.currency) + ")"
                          ),
                        ]),
                      ]),
                      _vm._l(_vm.order.positions, function (position) {
                        return _c("tr", { key: position.position }, [
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.getPositionNumber(position)) +
                                " "
                            ),
                          ]),
                          _c("td", [
                            _vm._v(" " + _vm._s(position.quantity) + " "),
                          ]),
                          _c(
                            "td",
                            [
                              position.materialname ||
                              position.includedSubarticleIds
                                ? _c("v-img", {
                                    attrs: {
                                      contain: "",
                                      width: "90px",
                                      height: "90px",
                                      src: _vm.getThumbnailUrl(
                                        position.blexonArticleId
                                      ),
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c("td", [
                            _vm._v(" " + _vm._s(position.blexonArticleId)),
                            _c("br"),
                            position.customerArticleId != null &&
                            position.customerArticleId != ""
                              ? _c("span", [
                                  _vm._v(_vm._s(position.customerArticleId)),
                                  _c("br"),
                                ])
                              : _vm._e(),
                            _vm._v(
                              " " + _vm._s(position.customerArticleName) + " "
                            ),
                            position.materialname != null &&
                            position.materialname != ""
                              ? _c("span", [
                                  _c("br"),
                                  _vm._v(_vm._s(position.materialname)),
                                ])
                              : _vm._e(),
                            position.materialname != null &&
                            position.materialname != ""
                              ? _c("span", [
                                  _c("br"),
                                  _vm._v(
                                    _vm._s(
                                      _vm.getEntgratartString(
                                        position.entgratart
                                      )
                                    ) + " "
                                  ),
                                ])
                              : _vm._e(),
                            position.gravur
                              ? _c("span", [_c("br"), _vm._v(" Gravur ")])
                              : _vm._e(),
                            position.features != null &&
                            position.features.length > 0
                              ? _c(
                                  "span",
                                  [
                                    _c("br"),
                                    _vm._l(
                                      position.features,
                                      function (feature, index) {
                                        return _c("span", { key: index }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(feature.count) +
                                              " x " +
                                              _vm._s(feature.name)
                                          ),
                                          index < position.features.length - 1
                                            ? _c("span", [_c("br")])
                                            : _vm._e(),
                                        ])
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            position.weldlinesAsText != ""
                              ? _c("span", [
                                  _c("br"),
                                  _vm._v(
                                    " " + _vm._s(position.weldlinesAsText) + " "
                                  ),
                                ])
                              : _vm._e(),
                            position.powderCoating
                              ? _c("span", [
                                  _c("br"),
                                  _c("span", {
                                    style:
                                      "display:inline-block; height: 10px; width: 10px; background-color:" +
                                      position.powderCoatingHexCode,
                                  }),
                                  _vm._v(
                                    "  RAL " +
                                      _vm._s(position.powderCoatingRalCode) +
                                      " |  " +
                                      _vm._s(
                                        position.powderCoatingTreatmentFace
                                      ) +
                                      " |  " +
                                      _vm._s(position.powderCoatingStructure) +
                                      " |  " +
                                      _vm._s(position.powderCoatingFinish) +
                                      " "
                                  ),
                                ])
                              : _vm._e(),
                            position.certificateTypes !== 0 &&
                            (_vm.state === 2 ||
                              _vm.state === 3 ||
                              _vm.state === 6)
                              ? _c("span", [
                                  _c("br"),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadCertificate(
                                            position,
                                            _vm.order
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Zertifikat 3.1")]
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                          _c("td", { staticStyle: { "text-align": "right" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("round2Decimal")(position.pricePerUnit)
                                ) +
                                " "
                            ),
                          ]),
                          _c("td", { staticStyle: { "text-align": "right" } }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("round2Decimal")(
                                    position.pricePerUnit * position.quantity
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ])
                      }),
                      _c("tr", [
                        _c("td"),
                        _c("td"),
                        _c("td"),
                        _c("td", [
                          _vm._v(
                            " MwSt (" + _vm._s(_vm.order.mwStSatz) + "%) "
                          ),
                        ]),
                        _c("td"),
                        _c("td", { staticStyle: { "text-align": "right" } }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("round2Decimal")(_vm.getMwSt())) +
                              " "
                          ),
                        ]),
                      ]),
                      _c("tr", { staticClass: "total" }, [
                        _c("td"),
                        _c("td"),
                        _c("td"),
                        _c("td", [_c("strong", [_vm._v("Total")])]),
                        _c("td"),
                        _c("td", { staticStyle: { "text-align": "right" } }, [
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm._f("round2Decimal")(_vm.getTotal()))
                            ),
                          ]),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.certificateError,
            callback: function ($$v) {
              _vm.certificateError = $$v
            },
            expression: "certificateError",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-btn",
                {
                  staticClass: "blexon-button",
                  attrs: { fab: "", fixed: "", top: "", right: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c("v-card-title", [
                _c("h2", { staticClass: "text-h5" }, [
                  _vm._v("Zertifikat kann nicht geladen werden"),
                ]),
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Das Zertifikat kann aktuell nicht geladen werden. Der Dienst scheint nicht verfügbar."
                ),
                _c("br"),
                _vm._v(" Bitte wenden Sie sich an unser Helpdesk. "),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "blexon-button",
                      on: {
                        click: function ($event) {
                          _vm.certificateError = false
                        },
                      },
                    },
                    [_vm._v("Schliessen")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }