
	import { Component, Vue, Prop, Watch } from "vue-property-decorator";
	import httpClient from "../../store/httpClient";

	@Component
	export default class RequestCancellation extends Vue {
		@Prop()
		private orderId!: number;
		@Prop()
		private orderProcessedInMeantime!: boolean;
		private acceptingCosts: boolean = false;
		private forceCancellation: boolean = false;
		private toggleAll: boolean = false;
		private order: any = {};
		private fixCost: number = -1;

		private async mounted() {
			if (!this.order || this.order.orderId !== this.orderId) {
				await this.loadOrder();
			}
			if (this.fixCost === -1) {
				await this.loadFixCost();
			}
		}

		private get positions() {
			return this.order.positions.filter((p: any) => (p.materialname !== null || (p.includedSubarticleIds && p.includedSubarticleIds.length > 0)) &&
				!this.order.positions.some((po: any) => po.includedSubarticleIds && po.includedSubarticleIds.some((sp: any) => sp == p.blexonArticleId)))
		}

		private get isLoading() {
			return this.$store.state.loading;
		}
		private set isLoading(state: boolean) {
			this.$store.commit("setLoading", state);
		}

		private get cancellationRequestIsValid() {
			return (
				!this.isLoading &&
				this.acceptingCosts &&
				this.order != null &&
				this.order.positions != null &&
				this.order.positions.some((p: any) => p.isSelected)
			);
		}

		private async loadFixCost() {
			try {
				const response = await httpClient().get(`order/GetFixCostOfCancellation`);
				this.fixCost = response.data;
			} catch (err) {
				this.$store.dispatch("setSnackbarErrorText", "Stornierungspauschale konnte nicht geladen werden.");
			}
		}

		private async loadOrder() {
			try {
				this.isLoading = true;
				const response = await httpClient().get(`order/getOrderConfirmation?orderId=${this.orderId}`);
				console.log(response)
				let orderData = response.data;
				orderData.positions.forEach((pos: any) => {
					pos.isSelected = false;
				});
				this.order = {
					orderId: orderData.blexonOrderId,
					fertigerId: orderData.fertigerId,
					currency: orderData.currency,
					positions: orderData.positions,
				};
			} catch (err) {
				this.$store.dispatch("setSnackbarErrorText", "Auftragspositionen konnten nicht geladen werden.");
			} finally {
				this.isLoading = false;
			}
		}

		private async requestOrderCancellation() {
			if (!this.cancellationRequestIsValid) return false;
			console.log("requesting order cancellation...");
			try {
				this.isLoading = true;

				let orderPositions:any = []
				for (const position of this.order.positions) {
					if (position.isSelected) {
						orderPositions.push({
							articleId: parseInt(position.blexonArticleId),
							position: position.position,
						})
						// All subarticles are cancelled as well
						for (const subpositionId of position.includedSubarticleIds) {
							const subposition = this.order.positions.find((p:any) => p.blexonArticleId == subpositionId)
								orderPositions.push({
								articleId: parseInt(subposition.blexonArticleId),
								position: subposition.position,
							})
						}
					}
				}

				await httpClient().post(
					`Order/RequestOrderCancellation?orderId=${this.order.orderId}&force=${this.forceCancellation}`,
					orderPositions
				);

				this.$store.dispatch("setSnackbarText", "Stornierung wurde beantragt.");

				// Reload cancellations to get the updated list
				this.$store.dispatch('reloadCancellationRequests');				
				this.goToOrders();
			} catch (err) {
				this.$store.dispatch("setSnackbarErrorText", "Stornierung konnte nicht beantragt werden.");
			} finally {
				this.isLoading = false;
			}
		}

		private toggleAllPositions() {
			this.order.positions.forEach((p: any) => {
				p.isSelected = this.toggleAll;
			});
		}

		private getThumbnailUrl(articleId: number) {
			return this.$store.getters.getThumbnailUrl(articleId, undefined);
		}

		private goToOrders() {
			this.$router.push("/Orders");
		}

		private getPrice(position: any) {
			if (!position.includedSubarticleIds || position.includedSubarticleIds.length <= 0) {
				return position.pricePerUnit
			} else {
				let price = position.pricePerUnit
				for (const subarticleId of position.includedSubarticleIds) {
					const subarticle = this.order.positions.find((p: any) => p.blexonArticleId == subarticleId)
					price += subarticle.pricePerUnit * subarticle.quantity / position.quantity
				}
				return price
			}
		}
	}
