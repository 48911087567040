
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import ArticleMultiDeleteDialog from '@/views/Dialogs/ArticleMultiDeleteDialog.vue'
import { CustomerArticleDto } from '@/dtos/customerArticleDto';
import IsPowdercoatingPossibleDialog from '../Dialogs/IsPowdercoatingPossibleDialog.vue';
import ArticleTreatmentEditDialog from '../Dialogs/ArticleTreatmentEditDialog.vue';

@Component({
    components: {
        ArticleMultiDeleteDialog,
        ArticleTreatmentEditDialog,
        IsPowdercoatingPossibleDialog,
    }
})
export default class Multiedit extends Vue {

    private articleMultiDeleteDialog: boolean = false;
    private isPowdercoatingPossibleDialog: boolean = false;
    private articleTreatmentEditDialog: boolean = false;

    private get selectedArticlesCount() {
        return this.$store.state.selectedArticleIds.length;
    }
    private get isMultiSelectionMode() {
        return this.selectedArticlesCount > 0;
    }

    private get selectedArticles() {
        return this.$store.state.articles
            .filter((a:CustomerArticleDto) => this.$store.state.selectedArticleIds.includes(a.articleId));
    }

    private get selectedArticleIds() {
        return this.selectedArticles.map((sa: CustomerArticleDto) => sa.articleId);
    }

    private getSubArticlesOfArticle(article: CustomerArticleDto) {
        return this.$store.state.articles
            .filter((a:CustomerArticleDto) => article.subArticleIds.includes(a.articleId));
    }

    private get isPowderCoatingAvailable() {
        return this.$store.state.isPowderCoatingAvailable;
    }

    private clearSelection() {
        this.$store.commit('clearSelectedArticleIds');
    }

}

